import React, { useState } from "react";

import AccountPanel from "../../partials/settings/AccountPanel";
import Header from "../../partials/Header";
import SettingsLayout from "./SettingsLayout";
import SettingsSidebar from "../../partials/settings/SettingsSidebar";
import Sidebar from "../../partials/Sidebar";

function Account() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SettingsLayout>
      <AccountPanel />
    </SettingsLayout>
  );
}

export default Account;
