import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Cookies } from "react-cookie";
import { API_DOMAIN } from '../../config'
import { Logger } from '../../utils/Logger'
import { setErrorMsg } from './../errorSlice'

const backendURL = API_DOMAIN;
const cookies = new Cookies();

export const userLogin = createAsyncThunk(
  "/users/login",
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${backendURL}/users/login`,
        { email, password },
        config
      )
      if(!data.data.token) {
				var msg = 'Incorrect username or password. Please try again!'
				dispatch(setErrorMsg(msg))
        return rejectWithValue(msg)
      }

      const profileConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + data.data.token
        },
      }
      const profileUrl = backendURL + '/users/profile'
      const res = await axios.get(profileUrl, profileConfig)
      if(!res.data.userdetails) {
				var msg = 'Incorrect username or password. Please try again!'
				dispatch(setErrorMsg(msg))
        return rejectWithValue(msg)
      }

      // store user's token in cookie
      cookies.set("userToken", data.data.token, { maxAge: 3600000 });
      return {...data.data, ...res.data}
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
				dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message);
      } else {
				dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "users/register",
  async ({ firstName, email, password }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        `${backendURL}/users/register`,
        { firstName, email, password },
        config
      );
    } catch (error) {
      if (error.response && error.response.data.message) {
				dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message);
      } else {
				dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message);
      }
    }
  }
)

export const selfRegisterUser = createAsyncThunk(
  "auth/selfregister",
  async (user, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(`${backendURL}/users/selfRegister`, user, config);
    } catch (error) {
      if (error.response && error.response.data.message) {
				dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message);
      } else {
				dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message);
      }
    }
  }
);

export const activateUser = createAsyncThunk(
  "auth/activateuser",
  async (userId, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        `${backendURL}/users/activateUser`,
        { userId },
        config
      );
    } catch (error) {
      if (error.response && error.response.data.message) {
				dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message);
      } else {
				dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message);
      }
    }
  }
);
