import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import qs from "qs";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";
import { setGlobalMsg } from "./../globalMessageSlice";
import { setErrorMsg } from "./../errorSlice";
import { getCookie } from "../../utils/Utils";
import { setLoading } from "./purchaseordersSlice";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = getCookie("userToken") || null;


export const fetchAccessToken = createAsyncThunk(
  "purchase/token",
  async ({tenantId, clientId, clientSecret, username, password}, { rejectWithValue }) => {
    try {
			const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
			Logger.log('-----tokenEndpoint:::----', tokenEndpoint);
			const tokenParams = {
        client_id: clientId,
        client_secret: clientSecret,
        scope: 'https://api.businesscentral.dynamics.com/.default',
        username: username,
        password: password,
        grant_type: 'password'
      };
      Logger.log(
        "--------state/action - fetchAccessToken - tokenParams:",
        tokenParams
      );

      const response = await axios.post(tokenEndpoint, qs.stringify(tokenParams), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      Logger.log(
        "state/action - fetchAccessToken - res:",
        response
      );


    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        setLoading(false)
        return rejectWithValue(error.response.data.message);
      } else {
        setLoading(false)
        return rejectWithValue(error.message);
      }
    }
	}
);













export const fetchPurchaseorders = createAsyncThunk(
  "purchaseorders/list",
  async (page, { rejectWithValue, dispatch }) => {
    try {
			setLoading(true)
      Logger.log(
        "state/action - fetchPurchaseorders - page:" + page + ", token:",
        token
      );
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/purchaseorder";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("------------------state/action - fetchPurchaseorders - url:", url);

      const { data } = await axios.get(url, config);
			const obj = { 
				'clientId': '5c03da75-4f78-4ffb-8431-7ea3540ced19', 
				'tenantId': 'ec0e4c89-573c-4e78-afab-9bbc4414cdde',
				'clientSecret' : 'OE38Q~~HTWsZw6dB5j2eS-_KxUoAlk22mHA10aE_',
				'username': 'prasad@cropwings.com',
				'password': 'F!229516952246uk'
			};
      const { tokendata } = await dispatch(fetchAccessToken(obj));
			Logger.log("state/action ----- fetchPurchaseorders - tokendata:", tokendata);
      Logger.log("state/action - fetchPurchaseorders - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
				setLoading(false)
        return rejectWithValue(error.response.data.message);
      } else {
				setLoading(false)
        return rejectWithValue(error.message);
      }
    }
  }
);

export const approvePurchaseOrders = createAsyncThunk(
  "purchaseorders/approve",
  async ({ ids }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      // configure header's Content-Type as JSON

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - approvePurchaseOrders ----", token);
      const { data } = await axios.post(
        `${backendURL}/purchaseorder/approve`,
        {
          selectedIds: ids,
        },
        config
      );
      Logger.log("state/action - approvePurchaseOrders - Zones DATA:", data);
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - purchase order approve - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPurchaseorderDetails = createAsyncThunk(
  "purchaseorders/details",
  async ({ id }, { rejectWithValue }) => {
    try {
			setLoading(true)
      Logger.log("state/action - fetchPurchaseorderDetails ----", token);
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = `${backendURL}/purchaseorder/details/${id}`;

      Logger.log("state/action - fetchPurchaseorderDetails - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log(
        "state/action - fetchPurchaseorderDetails - Zones DATA:",
        data
      );
			setLoading(false)
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
				setLoading(false)
        return rejectWithValue(error.response.data.message);
      } else {
				setLoading(false)
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createPOAction = createAsyncThunk(
  "purchaseorder/create",
  async (NewPO, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - Create PO Action ----", token);
      const body = {
        external_id: NewPO.po_number.value,
        purchase_order_number: NewPO.po_number.value,
        purchase_order_date: NewPO.purchase_order_date.value,
        posting_date: "",
        supplier_name: NewPO.supplier_name.value,
        supplier_address_line1: NewPO.supplier_address1.value,
        supplier_pincode: NewPO.supplier_zipcode.value,
        shipto_address_line1: NewPO.shipto_address1.value,
        created_by_user_id: 1,
        lineitems: NewPO.po_list.values
          ? NewPO.po_list.values.map((item, i) => ({
              id: item.properties.item_number.value || "",
              lineObjectNumber: item.properties.item_number.value || "",
              purchase_order_number: item.properties.item_number.value || "",
              external_item_id:
                item.properties.Supplier_item_number.value || "",
              external_po_item_id: item.properties.item_number.value || "",
              item_description: item.properties.Item_Desc1.value || "",
              item_description2: item.properties.item_desc2.value || "",
              unitOfMeasureCode: item.properties.unit.value || "",
              quantity: item.properties.item_quantity.value || "",
              unit_of_measure_code: item.properties.unit.value || "",
              supplier_item_number:
                item.properties.Supplier_item_number.value || "",
              gtin_number: item.properties.GTIN_number.value || "",
              itemName: item.properties.item_name.value || "",
            }))
          : [],
      };
      Logger.log("state/action - PO insert BODY.....:", body);
      const response = await axios.post(
        `${backendURL}/purchaseorder/create`,
        body,
        config
      );
      const data = response.data;
      Logger.log("state/action - PO insert DATA.....:", data);
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message));
        dispatch(fetchPurchaseorders());
      }
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - PO - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const confirmPurchaseOrder = createAsyncThunk(
  "purchaseorder/confirmPurchaseOrder",
  async (ocrOC, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      const body = {
        lineitems: ocrOC.OC_items.values.map((item, i) => ({
          confirmed_quantity: item.properties["Confirmed qty."]
            ? item.properties["Confirmed qty."].value
            : "",
            supplier_item_number: item.properties.supplier_item_numer
            ? item.properties.supplier_item_numer.value
            : "",
        })),
      };
      console.log(
        "state/action - confirmPurchaseOrder ----",
        body,
        "ponumber",
        ocrOC.PO_number.value
      );
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - confirmPurchaseOrder ----", token);
      // const params = new URLSearchParams({
      //   purchase_order_number: ocrOC.PO_number.value,
      // }).toString();

      const url = `${backendURL}/purchaseorder/confirmPurchaseOrder/` + ocrOC.PO_number.value;
      const { data } = await axios.patch(url, body, config);
      Logger.log("state/action - confirmPurchaseOrder - Zones DATA:", data);
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - purchase order confirm - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);
