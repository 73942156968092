import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";
import { setGlobalMsg } from "./../globalMessageSlice";
import { setErrorMsg } from "./../errorSlice";
import { getCookie } from "../../utils/Utils";
import { setLoading } from "./packedlistSlice";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = getCookie("userToken") || null;
export const fetchPackedlistOrders = createAsyncThunk(
  "packedlist/list",
  async (page, { rejectWithValue }) => {
    try {
			setLoading(true)
      Logger.log(
        "state/action - fetchPackedlistOrders - page:" + page + ", token:",
        token
      );
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/packedlist";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("state/action - fetchPackedlistOrders - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchPackedlistOrders - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
				setLoading(false)
        return rejectWithValue(error.response.data.message);
      } else {
				setLoading(false)
        return rejectWithValue(error.message);
      }
    }
  }
);

export const approvePackedList = createAsyncThunk(
  "packedlist/approve",
  async ({ ids }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      // configure header's Content-Type as JSON

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - approvePackedList ----", token);
      const { data } = await axios.post(
        `${backendURL}/packedlist/approve`,
        {
          selectedIds: ids,
        },
        config
      );
      Logger.log("state/action - approvePackedList - Zones DATA:", data);
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - approvePackedList approve - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPackedListDetails = createAsyncThunk(
  "packedlist/details",
  async ({ id }, { rejectWithValue }) => {
    try {
			setLoading(true)
      Logger.log("state/action - fetchPackedlistDetails ----", token);
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = `${backendURL}/packedlist/details/${id}`;

      Logger.log("state/action - fetchPackedlistDetails - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log(
        "state/action - fetchPackedlistDetails - Zones DATA:",
        data
      );
			setLoading(false)
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
				setLoading(false)
        return rejectWithValue(error.response.data.message);
      } else {
				setLoading(false)
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createPLAction = createAsyncThunk(
  "packedlist/create",
  async (NewPO, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - Create PL Action ----", token);
      const body = {
        external_id: NewPO.po_number.value,
        purchase_order_number: NewPO.po_number.value,
        purchase_order_date: NewPO.purchase_order_date.value,
        posting_date: "",
        supplier_name: NewPO.supplier_name.value,
        supplier_address_line1: NewPO.supplier_address1.value,
        supplier_pincode: NewPO.supplier_zipcode.value,
        shipto_address_line1: NewPO.shipto_address1.value,
        created_by_user_id: 1,
        lineitems: NewPO.po_list.values
          ? NewPO.po_list.values.map((item, i) => ({
              id: item.properties.item_number.value || "",
              lineObjectNumber: item.properties.item_number.value || "",
              purchase_order_number: item.properties.item_number.value || "",
              external_item_id:
                item.properties.Supplier_item_number.value || "",
              external_po_item_id: item.properties.item_number.value || "",
              item_description: item.properties.Item_Desc1.value || "",
              item_description2: item.properties.item_desc2.value || "",
              unitOfMeasureCode: item.properties.unit.value || "",
              quantity: item.properties.item_quantity.value || "",
              unit_of_measure_code: item.properties.unit.value || "",
              supplier_item_number:
                item.properties.Supplier_item_number.value || "",
              gtin_number: item.properties.GTIN_number.value || "",
              itemName: item.properties.item_name.value || "",
            }))
          : [],
      };
      Logger.log("state/action - PO insert BODY.....:", body);
      const response = await axios.post(
        `${backendURL}/purchaseorder/create`,
        body,
        config
      );
      const data = response.data;
      Logger.log("state/action - PO insert DATA.....:", data);
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message));
        dispatch(fetchPurchaseorders());
      }
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - PO - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const confirmPackedListOrder = createAsyncThunk(
  "packedlist/confirmPackedListOrder",
  async (ocrOC, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      const body = {
        lineitems: ocrOC.OC_items.values.map((item, i) => ({
          confirmed_quantity: item.properties["Confirmed qty."]
            ? item.properties["Confirmed qty."].value
            : "",
            supplier_item_number: item.properties.supplier_item_numer
            ? item.properties.supplier_item_numer.value
            : "",
        })),
      };
      console.log(
        "state/action - confirmPackedListOrder ----",
        body,
        "ponumber",
        ocrOC.PO_number.value
      );
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - confirmPackedlistOrder ----", token);
      // const params = new URLSearchParams({
      //   purchase_order_number: ocrOC.PO_number.value,
      // }).toString();

      const url = `${backendURL}/packedlist/confirmPackedlist/` + ocrOC.PO_number.value;
      const { data } = await axios.patch(url, body, config);
      Logger.log("state/action - confirmPackedlistOrder - Zones DATA:", data);
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - packedList confirm - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);
