// purchaseordersList.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PackedlistorderDetails from "../partials/packedlist/PackedlistorderDetails";
import PackedlistTable from "../partials/packedlist/PackedlistTable";
import NoSidebarPageLayout from "./NoSidebarPageLayout";
import ApproveButton from "../partials/actions/ApproveButton";
import { PageContainer, PageActions } from "./component/PageContainer";
import {
  confirmPackedListOrder,
  createPLAction,
  fetchPackedlistOrders,
} from "../state/packedlist/packedlistActions";
import Dropzone from "./DropZone";
import {
  AzureKeyCredential,
  DocumentAnalysisClient,
} from "@azure/ai-form-recognizer";
import { set } from "react-hook-form";

function PackedList() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [pageType, setPageType] = useState("list");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();
  const [oc, setOC] = useState();
  // const [currentDocument, setCurrentDocument] = useState();


  const openModal = () => {
    setIsOpen(true);
    if (pageType === "list") {
    }
    if (pageType === "details") {
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    console.log("close modal");
  };
  const dispatch = useDispatch();
  const packedListOrders = useSelector(
    (state) => state.packedlist.packedListOrders
  );

  useEffect(() => {
    dispatch(fetchPackedlistOrders());
  }, []);

  useEffect(() => {
    console.log("PL Details:", packedListOrders);
  }, []);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handlePageAction = (type, selectedItem) => {
    setPageType(type);
    if (selectedItem) {
      const selItem = {
        id: selectedItem.id,
        packed_list_number: selectedItem.listitem.packed_list_number,
      };
      setSelectedItem(selItem);
    }
  };
  const backToList = () => {
    handlePageAction("list");
  };
  const onUpload = (file) => {
    if (file) {
      setFile(file);
      makeFileApiRequest(file);
    }
  };
  const onOCUpload = (file) => {
    if (file) {
      setOC(file);
      makeOCFileApiRequest(file);
    }
  };
  const key = "881fdf23922547c2a72794923b9b7e95";
  const endpoint = "https://westeurope.api.cognitive.microsoft.com/";

  const makeFileApiRequest = async (file) => {
    setIsLoading(true);
    const client = new DocumentAnalysisClient(
      endpoint,
      new AzureKeyCredential(key)
    );

    //const modelId = "po-model-v10";
    const modelId = "PO-new-model1";

    const poller = await client.beginAnalyzeDocument(modelId, file[0]);

    const response = await poller.pollUntilDone();

    // response.documents?.length && setResponse(response.documents[0].fields);
    if (response.documents?.length) {
      // setCurrentDocument(response.documents[0].fields);
      createNewPO(response.documents[0].fields);
    }
  };
  const makeOCFileApiRequest = async (file) => {
    setIsLoading(true);
    const client = new DocumentAnalysisClient(
      endpoint,
      new AzureKeyCredential(key)
    );

    //const modelId = "oc_model_v1";
    const modelId = "OC_New_v1";

    const poller = await client.beginAnalyzeDocument(modelId, file[0]);

    const response = await poller.pollUntilDone();
    console.log("Res",response.documents[0].fields);
    if (response.documents?.length) {
      createNewOC(response.documents[0].fields);
    
    }
  };

  const createNewOC = (currentDocument) => {
    dispatch(confirmPackedListOrder(currentDocument));

    setIsLoading(false);
    setOC(null);
    setIsOpen(false);
  };
  const createNewPO = (currentDocument) => {
    dispatch(createPLAction(currentDocument));

    setIsLoading(false);
    setFile(null);
    setIsOpen(false);
  };

  const getPageTitle = (pageType) => {
    return pageType === "details"
      ? "Packedlist - " + selectedItem.packed_list_number
      : "Packedlist";
  };


  return (
    <>
      <NoSidebarPageLayout>
        <PageContainer
          title={getPageTitle(pageType)}
          actions={
            <>
              {pageType === "details" && (
                <>
                  <button
                    onClick={backToList}
                    className="flex text-white bg-black hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                  >
                    Back
                  </button>
                  <button
                    onClick={openModal}
                    className="flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing OC..." : "Upload OC..."}

                    {/* file svg icon */}
                    {!isLoading && (
                      <svg
                        className="w-4 h-4 ms-2  "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    )}
                  </button>
                </>
              )}
              {pageType === "list" && (
                <button
                  onClick={openModal}
                  className="flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                  disabled={isLoading}
                >
                  {isLoading ? "Processing PL..." : "Upload PL"}

                  {/* file svg icon */}
                  {!isLoading && (
                    <svg
                      className="w-4 h-4 ms-2  "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  )}
                </button>
              )}
            </>
          }
        >
          {pageType === "details" ? (
            <PackedlistorderDetails data={selectedItem} />
          ) : (
            <PackedlistTable
              packedListOrders={packedListOrders}
              selectedItems={handleSelectedItems}
              handlePageAction={handlePageAction}
            />
          )}
        </PageContainer>
      </NoSidebarPageLayout>
      {pageType == "list" && isOpen && (
        <div className="backdrop-blur-lg overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div
            tabIndex="-1"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Upload PO
                  </h3>
                  <button
                    onClick={closeModal}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5">
                  {isLoading ? (
                    <div>Processing...</div>
                  ) : (
                    <Dropzone onUpload={onUpload} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pageType == "details" && isOpen && (
        <div className="backdrop-blur-lg overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div
            tabIndex="-1"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Upload Order Confirmation
                  </h3>
                  <button
                    onClick={closeModal}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 md:p-5">
                  {isLoading ? (
                    <>
                      <div
                        className={`flex justify-center items-center w-full h-48 border-2 border-dashed rounded-lg p-5
      hover:bg-sky-50 hover:border-sky-400
       "bg-sky-50 border-sky-400`}
                       
                      >
                        <div
                         
                          style={{ display: "none" }}
                         
                          
                        />
                        <p>
                          Processing File...
                         </p>
                      </div>
                    </>
                  ) : (
                    <Dropzone onUpload={onOCUpload} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PackedList;
