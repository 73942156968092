import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = cookies.get("userToken") || null;
export const fetchSuppliers = createAsyncThunk(
  "suppliers/list",
  async (page, { rejectWithValue }) => {
    try {
      Logger.log(
        "state/action - fetchSuppliers - page:" + page + ", token:",
        token
      );
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/supplier";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("state/action - fetchSuppliers - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchSuppliers - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchSupplierDetails = createAsyncThunk(
  "suppliers/details",
  async ({ id }, { rejectWithValue }) => {
    try {
      Logger.log("state/action - fetchSupplierDetails ----", token);
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = `${backendURL}/supplier/details/${id}`;

      Logger.log("state/action - fetchSupplierDetails - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchSupplierDetails - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchSupplierAddresses = createAsyncThunk(
  "suppliers/addresses",
  async ({ id }, { rejectWithValue }) => {
    try {
      Logger.log("state/action - fetchSupplierAddresses ----", token);
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = `${backendURL}/supplieraddress?sid=${id}`;

      Logger.log("state/action - fetchSupplierAddresses - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchSupplierAddresses - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchSupplierPOList = createAsyncThunk(
  "suppliers/polist",
  async ({ id }, { rejectWithValue }) => {
    try {
      Logger.log("state/action - fetchSupplierPOList ----", token);
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = `${backendURL}/purchaseorder?sid=${id}`;

      Logger.log("state/action - fetchSupplierPOList - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchSupplierPOList - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
