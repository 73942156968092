import React from "react";
import { APP_NAME } from "./../config";


function Onboarding02({ register }) {
  return (
    <>
      <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
        {`Sign up to use ${APP_NAME}`}
      </h1>
      <div className="space-y-4 mb-8">
				<div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="company-name"
          >
            Company Name <span className="text-rose-500">*</span>
          </label>
          <input
            id="company-name"
            className="form-input w-full"
            type="text"
            {...register("company_name")}
            required
          />
        </div>
				<div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="company-name"
          >
            Corporate number
            <span className="text-rose-500">*</span>
          </label>
          <input
            id="company-name"
            className="form-input w-full"
            type="text"
            {...register("organization_no")}
            required
          />
        </div>
				<div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="address"
          >
            Address <span className="text-rose-500">*</span>
          </label>
          <input
            id="address"
            className="form-input w-full"
            type="text"
            {...register("address")}
            required
          />
        </div>
        <div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="company-name"
          >
            Contact Person <span className="text-rose-500">*</span>
          </label>
          <input
            id="company-name"
            className="form-input w-full"
            type="text"
            {...register("contact_person")}
            required
          />
        </div>
				 <div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="company-name"
          >
            Email <span className="text-rose-500">*</span>
          </label>
          <input
            id="company-name"
            className="form-input w-full"
            type="text"
            {...register("email_id")}
            required
          />
        </div>
        <div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="company-name"
          >
            Password <span className="text-rose-500">*</span>
          </label>
          <input
            id="company-name"
            className="form-input w-full"
            type="password"
            {...register("password")}
            required
          />
        </div>
        <div>
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="company-name"
          >
            Confirm Password <span className="text-rose-500">*</span>
          </label>
          <input
            id="company-name"
            className="form-input w-full"
            type="password"
            {...register("confirm_password")}
            required
          />
        </div>

      </div>
    </>
  );
}

export default Onboarding02;
