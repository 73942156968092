import React, { useState, useEffect } from "react";
import ShipmentTimeSlotForm from "../partials/shipments/AddTimeSlot";
import ShipmentCalendar from "../partials/shipments/Calendar";
import ShipmentsTable from "../partials/shipments/ShipmentsTable";
import DeleteButton from "../partials/actions/DeleteButton";
import NoSidebarPageLayout from "./NoSidebarPageLayout";
import { PageContainer, ShipmentPageActions } from "./component/PageContainer";

function ShipmentsList() {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [pageType, setPageType] = useState("list");

    const handleSelectedItems = (selectedItems) => {
        setSelectedItems([...selectedItems]);
    };

    const handlePageAction = (type, selectedItem) => {
        setPageType(type);
        setSelectedItem(selectedItem);
    };

    const actions = (
        <ShipmentPageActions
            pageType={pageType}
            handlePageAction={handlePageAction}
            deleteButton={<DeleteButton selectedItems={selectedItems} />}
            addButtonLabel="Shipments Calendar"
            listButtonLabel="List Shipments"
        />
    );

    return (
        <NoSidebarPageLayout>
            <PageContainer title={pageType !== "calendar" ? "Shipments" : null} actions={pageType !== "calendar" ? actions : null} pageType={pageType}>
                {/* Table or form based on pageType */}
                {pageType === "add" ? (
                    <ShipmentTimeSlotForm
                        data={selectedItem}
                        handlePageAction={handlePageAction}
                    />
                ) : pageType === "calendar" ? (
                    <ShipmentCalendar
                        handlePageAction={handlePageAction}
                    />
                ) : (
                    <ShipmentsTable
                        selectedItems={handleSelectedItems}
                        handlePageAction={handlePageAction}
                    />
                )}
            </PageContainer>
        </NoSidebarPageLayout>
    );

}

export default ShipmentsList;
