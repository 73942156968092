import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import PurchaseordersTableItem from './PurchaseordersTableItem';
import { fetchPurchaseorders } from '../../state/purchaseorders/purchaseordersActions';
import PaginationClassic from '../../components/PaginationClassic';
import { Logger } from '../../utils/Logger';
import TableHeader from '../../pages/component/TableHeader';
import { formatDate } from '../../utils/Utils'

const PurchaseordersTable = ({
  selectedItems,
  handlePageAction,
}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const purchaseordersList = useSelector(
    (state) => state.purchaseorders.purchaseordersList
  );
  useEffect(() => {
    Logger.log('PurchaseordersTable - fetching page - ....', currentPage);
    dispatch(fetchPurchaseorders(currentPage));
  }, [currentPage]);
  useEffect(() => {
    if (purchaseordersList && purchaseordersList.dataList) {
			setList(purchaseordersList.dataList);
      // Reset isChecked state when data changes
      setIsCheck([]);
      setSelectAll(false);
    }
  }, [purchaseordersList]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id, checked) => {
    setSelectAll(false);
    setIsCheck((prevIsCheck) => {
      if (checked) {
        return [...prevIsCheck, id];
      } else {
        return prevIsCheck.filter((item) => item !== id);
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    selectedItems(isCheck);
  }, [isCheck]);

  return (
    <>
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
        <header className="px-5 py-4">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">
            All Purchase Orders{' '}
            <span className="text-slate-400 dark:text-slate-500 font-medium">
              {purchaseordersList ? purchaseordersList.totalCount : 0}
            </span>
          </h2>
        </header>
        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            {list.length > 0 ? (
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {/* Table header */}
                <TableHeader
                  columns={[
                    'Purchase Order',
                    'Supplier Name',
                    'PO Date',
                    'Expected Delivery Date',
                    'Shipping Destination',
                    // 'Status',
                  ]}
                  selectAll={selectAll}
                  handleSelectAll={handleSelectAll}
                />
                {/* Table body */}
                {list.map((listitem) => {
                  return (
                    <PurchaseordersTableItem
                      key={listitem.id}
                      id={listitem.id}
											listitem={listitem}
                      purchase_order={listitem.purchase_order}
                      supplier_name={listitem.supplier_name}
                      created_on={formatDate(listitem.created_on)}
                      expected_delivery_date={formatDate(listitem.expected_delivery_date)}
                      shipping_destination={listitem.shipping_destination}
                      status={listitem.status}
                      handleClick={handleClick}
                      handlePageAction={handlePageAction}
                      isChecked={isCheck.includes(listitem.id)}
                    />
                  );
                })}
              </table>
            ) : (
              <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                No Purchase orders found
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <PaginationClassic
          currentPage={currentPage}
          total={purchaseordersList ? purchaseordersList.totalCount : 0}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  purchaseordersList: state.purchaseorders.purchaseordersList ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  fetchPurchaseorders: (...args) => dispatch(fetchPurchaseorders(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseordersTable);
