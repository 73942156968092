import React from 'react';

const TableHeader = ({ columns, selectAll, handleSelectAll, showCheckbox = true }) => {
    return (
        <thead className="text-xs uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-slate-200 dark:border-slate-700">
            <tr>
                {showCheckbox && ( // Conditionally render the checkbox based on the showCheckbox prop
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="flex items-center">
                            <label className="inline-flex">
                                <span className="sr-only">Select all</span>
                                <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                            </label>
                        </div>
                    </th>
                )}
                {columns.map((column, index) => (
                    <th
                        key={index}
                        className={`px-2 ${index === 0 ? 'first:pl-5' : ''} ${index === columns.length - 1 ? 'last:pr-5' : ''
                            } py-3 whitespace-nowrap`}
                    >
                        <div className="font-semibold text-left">{column}</div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeader;
