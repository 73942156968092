import React, { useState } from "react";

import TimeslotForm from "../partials/settings/timeslots/Add";
import TimeslotTable from "../partials/settings/timeslots/TimeslotsTable";
import DeleteButton from "../partials/actions/DeleteButton";
import NoSidebarPageLayout from "./NoSidebarPageLayout";
import { PageContainer, PageActions } from "./component/PageContainer";

function TimeslotsList() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [pageType, setPageType] = useState("list");

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handlePageAction = (type, selectedItem) => {
    setPageType(type);
    if (selectedItem) {
      const selItem = { week_days: selectedItem.week_days, opening_time: selectedItem.opening_time, closing_time: selectedItem.closing_time, slot_duration: selectedItem.slot_duration, email_id: selectedItem.email_id, id: selectedItem.id, status: selectedItem.status }
      setSelectedItem(selItem);
    }
  };

  const actions = (
    <PageActions
      pageType={pageType}
      handlePageAction={handlePageAction}
      deleteButton={<DeleteButton selectedItems={selectedItems} />}
      addButtonLabel="Add New Time Slot"
      listButtonLabel="List Time Slots"
    />
  );

  return (
    <NoSidebarPageLayout>
      <PageContainer title="Time Slots" actions={actions}>
        {/* Table or form based on pageType */}
        {pageType === "add" || pageType === "edit" ? (
          <TimeslotForm
            selectedItems={handleSelectedItems}
            data={selectedItem}
            editMode={pageType === "edit"}
            handlePageAction={handlePageAction}
          />
        ) : (
          <TimeslotTable
            selectedItems={handleSelectedItems}
            handlePageAction={handlePageAction}
          />
        )}
      </PageContainer>
    </NoSidebarPageLayout>
  );
}

export default TimeslotsList;
