import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";
import { setGlobalMsg } from './../globalMessageSlice'
import { setErrorMsg } from "./../errorSlice";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = cookies.get("userToken") || null;

export const fetchTimeslots = createAsyncThunk(
  "timeslots/list",
  async (page, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/timeslot";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("state/action - fetchTimeslots - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchTimeslots - Time Slots DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createTimeslotAction = createAsyncThunk(
  "timeslots/create",
  async ({ week_days, opening_time, closing_time, slot_duration, email_id }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      // configure header's Content-Type as JSON

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - createCountryAction ----", token);
      const response = await axios.post(
        `${backendURL}/timeslot/create`,
        { week_days: week_days, opening_time: opening_time, closing_time: closing_time, slot_duration: slot_duration, email_id: email_id },
        config
      );
      const data = response.data
      Logger.log("state/action - time slot insert DATA.....:", data);
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - time slots - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTimeslotAction = createAsyncThunk(
  'timeslots/update',
  async ({ id, week_days, opening_time, closing_time, slot_duration, email_id }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
      Logger.log('state/action - updateTimeslotAction ----', token, id, week_days)
      const updateData = {};

      if (week_days) updateData.week_days = week_days;
      if (opening_time) updateData.opening_time = opening_time;
      if (closing_time) updateData.closing_time = closing_time;
      if (slot_duration) updateData.slot_duration = slot_duration;
      if (email_id) updateData.email_id = email_id;


      const response = await axios.patch(
        `${backendURL}/timeslot/update/${id}`,
        updateData,
        config
      )
      const data = response.data
      Logger.log('state/action - updateTimeslotAction - Time Slot update DATA.....:', response.status === 201, response, data)
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data
    } catch (error) {
      // return custom error message from API if any
      console.log('ERROR::::', error)
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message)
      } else {
        dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message)
      }
    }
  }
)

