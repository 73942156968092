import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activateUser } from "../state/auth/authActions";
import Logo from "../components/Logo";
import Banner2 from './../components/Banner2'

const ActivateUser = () => {
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.auth);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(activateUser(id));
    }
  }, []);

  return (
		<main className="bg-white dark:bg-slate-900">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full">
					<div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                <Logo />
							</div>
							<div className="px-8 pt-12 pb-8">	
							{success ? (
								<Banner2 type="success" open='true' className='no-close mb-4'>
									Successfully activated account
								</Banner2>
							) : (
								error && (
									<Banner2 type="error" open='true' className='no-close mb-4'>
										{error.split(":")[1]}
									</Banner2>
								)
							)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
  );
};

export default ActivateUser;
