import React, { useState } from "react";

import SupplierDetailsPage from "../partials/suppliers/SupplierDetails";
import SuppliersTable from "../partials/suppliers/SuppliersTable";
import DeleteButton from "../partials/actions/DeleteButton";
import NoSidebarPageLayout from "./NoSidebarPageLayout";
import { PageContainer, PageActions } from "./component/PageContainer";

function SuppliersList() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [pageType, setPageType] = useState("list");

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handlePageAction = (type, selectedItem) => {
    setPageType(type);
		if(selectedItem) {      
			const selItem = { id: selectedItem.id}      
			setSelectedItem(selItem);
		} 
  }; 

  return (
    <NoSidebarPageLayout>
      <PageContainer title="Suppliers">
        {/* Table or form based on pageType */}
        {pageType === "details" ? (          
          <SupplierDetailsPage
						data={selectedItem}
					/>
        ) : (
          <SuppliersTable
            selectedItems={handleSelectedItems}
            handlePageAction={handlePageAction}
          />
        )}
      </PageContainer>
    </NoSidebarPageLayout>
  );
}

export default SuppliersList;
