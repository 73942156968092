import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import TimeslotsTableItem from './TimeslotsTableItem'
import { fetchTimeslots } from "../../../state/timeslots/timeslotsActions"
import PaginationClassic from "../../../components/PaginationClassic"
import { Logger } from '../../../utils/Logger'
import TableHeader from "../../../pages/component/TableHeader"

const TimeslotsTable = ({
  selectedItems,
  handlePageAction,
  fetchTimeslots,
  timeslotsList }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    Logger.log('TimeslotsTable - fetching page - ....', currentPage);
    fetchTimeslots(currentPage);
  }, [currentPage, fetchTimeslots]);

  useEffect(() => {
    if (timeslotsList && timeslotsList.dataList) {
      setList(timeslotsList.dataList);
    }
  }, [timeslotsList]);  

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  }

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <>
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
        <header className="px-5 py-4">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">All Time Slots <span className="text-slate-400 dark:text-slate-500 font-medium">{timeslotsList.totalCount}</span></h2>
        </header>
        <div>

          {/* Table */}
          <div className="overflow-x-auto">
            {list.length > 0 ? (
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {/* Table header */}
                <TableHeader columns={['Id', 'Opening Days', 'Opening Time', 'Closing Time', 'Slot Duration', 'Email Id', 'Status', 'Actions']} selectAll={selectAll} handleSelectAll={handleSelectAll} />
                {/* Table body */}
                {
                  list.map(listitem => {
                    return (
                      <TimeslotsTableItem
                        key={listitem.id}
                        id={listitem.id}
                        week_days={listitem.week_days}
                        opening_time={listitem.opening_time}
                        closing_time={listitem.closing_time}
                        slot_duration={listitem.slot_duration}
                        email_id={listitem.email_id}
                        status={listitem.status}
                        handleClick={handleClick}
                        handlePageAction={handlePageAction}
                        isChecked={isCheck.includes(listitem.id)}
                      />
                    )
                  })
                }
              </table>
            ) : (
              <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                No Time Slots found
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <PaginationClassic
          currentPage={currentPage}
          total={timeslotsList.totalCount}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  timeslotsList: state.timeslots.timeslotsList ?? [],
})

const mapDispatchToProps = (dispatch) => ({
    fetchTimeslots: (...args) => dispatch(fetchTimeslots(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeslotsTable)
