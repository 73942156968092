import { createSlice } from "@reduxjs/toolkit"
import { fetchPackedlistOrders, fetchPackedListDetails, approvePackedList, createPLAction, confirmPackedListOrder } from "./packedlistActions"

const initialState = {
    packedListOrders: [],
    packedListDetails: {},
    loading: false,
}

const packedlistSlice = createSlice({
    name: "packedlist",
    initialState,
    reducers: {
        packedListOrders: (state, action) => {
            state.packedListOrders = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPackedlistOrders.fulfilled, (state, { payload }) => {
                console.log('fetchPackedlistOrders REDUCER FULLFILLED.....', payload)
                state.packedListOrders = payload
                state.loading = false
            })
            .addCase(fetchPackedListDetails.fulfilled, (state, { payload }) => {
                console.log('fetchPackedListDetails REDUCER FULLFILLED.....', payload)
                state.packedListDetails = payload
                state.loading = false
            })
            .addCase(approvePackedList.fulfilled, (state, { payload }) => {
                console.log('approvePackedList REDUCER FULLFILLED.....', payload)
                state.packedListOrders = payload;
                state.loading = false
            })
            .addCase(createPLAction.fulfilled, (state, { payload }) => {
                console.log('createPLAction REDUCER FULLFILLED.....', payload)
                state.loading = false
            })
            .addCase(createPLAction.rejected, (state, { payload }) => {
                console.log('createPLAction REDUCER REJECTED.....', payload)
                state.loading = false
            })
            //confirmPurchaseOrder
            .addCase(confirmPackedListOrder.fulfilled, (state, { payload }) => {
                console.log('confirmPackedListOrder REDUCER FULLFILLED.....', payload)
                state.packedListDetails = payload
                state.loading = false
            })
            .addCase(confirmPackedListOrder.rejected, (state, { payload }) => {
                console.log('confirmPackedListOrder REDUCER REJECTED.....', payload)
                state.loading = false
            })
    },
})

export const { setLoading } = packedlistSlice.actions;
export default packedlistSlice.reducer;
