import React, { useState } from "react";

import CountriesForm from "../../partials/settings/countries/Add";
import CountriesTable from "../../partials/settings/countries/CountriesTable";
import DeleteButton from "../../partials/actions/DeleteButton";
import SettingsLayout from "./SettingsLayout";
import { PageContainer, PageActions } from "../component/PageContainer";

function CountriesList() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [pageType, setPageType] = useState("list");

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handlePageAction = (type, selectedItem) => {
    setPageType(type);
    if (selectedItem) {
      const selItem = { country: selectedItem.name, iso_code: selectedItem.iso_code, currency: selectedItem.currency, id: selectedItem.id, status: selectedItem.status }
      setSelectedItem(selItem);
    }
  };

  const actions = (
    <PageActions
      pageType={pageType}
      handlePageAction={handlePageAction}
      deleteButton={<DeleteButton selectedItems={selectedItems} />}
      addButtonLabel="Add New Country"
      listButtonLabel="List Countries"
    />
  );

  return (
    <SettingsLayout>
      <PageContainer title="Countries" actions={actions}>
        {/* Table or form based on pageType */}
        {pageType === "add" || pageType === "edit" ? (
          <CountriesForm
            selectedItems={handleSelectedItems}
            data={selectedItem}
            editMode={pageType === "edit"}
            handlePageAction={handlePageAction}
          />
        ) : (
          <CountriesTable
            selectedItems={handleSelectedItems}
            handlePageAction={handlePageAction}
          />
        )}
      </PageContainer>
    </SettingsLayout>
  );
}

export default CountriesList;
