import React, { useState } from "react";

import CurrenciesForm from "../../partials/settings/currencies/Add";
import CurrenciesTable from "../../partials/settings/currencies/CurrenciesTable";
import DeleteButton from "../../partials/actions/DeleteButton";
import SettingsLayout from "./SettingsLayout";
import { PageContainer, PageActions } from "../component/PageContainer";

function CurrenciesList() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [pageType, setPageType] = useState("list");

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handlePageAction = (type, selectedItem) => {
    setPageType(type);
    if (selectedItem) {
      const selItem = { currency: selectedItem.name, code: selectedItem.code, symbol: selectedItem.symbol, id: selectedItem.id, status: selectedItem.status }
      setSelectedItem(selItem);
    }
  };

  const actions = (
    <PageActions
      pageType={pageType}
      handlePageAction={handlePageAction}
      deleteButton={<DeleteButton selectedItems={selectedItems} />}
      addButtonLabel="Add New Currency"
      listButtonLabel="List Currencies"
    />
  );

  return (
    <SettingsLayout>
      <PageContainer title="Currencies" actions={actions}>
        {/* Table or form based on pageType */}
        {pageType === "add" || pageType === "edit" ? (
          <CurrenciesForm
            selectedItems={handleSelectedItems}
            data={selectedItem}
            editMode={pageType === "edit"}
            handlePageAction={handlePageAction}
          />
        ) : (
          <CurrenciesTable
            selectedItems={handleSelectedItems}
            handlePageAction={handlePageAction}
          />
        )}
      </PageContainer>
    </SettingsLayout>
  );
}

export default CurrenciesList;
