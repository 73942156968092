import "./css/style.css";
import "./charts/ChartjsConfig";

import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import ActivateUser from "./pages/ActivateUser";
import Analytics from "./pages/Analytics";
import Calendar from "./pages/Calendar";
import Campaigns from "./pages/Campaigns";
import Feed from "./pages/community/Feed";
import Forum from "./pages/community/Forum";
import ForumPost from "./pages/community/ForumPost";
import Meetups from "./pages/community/Meetups";
import MeetupsPost from "./pages/community/MeetupsPost";
import Profile from "./pages/community/Profile";
import UsersTabs from "./pages/community/UsersTabs";
import UsersTiles from "./pages/community/UsersTiles";
import AccordionPage from "./pages/component/AccordionPage";
import AlertPage from "./pages/component/AlertPage";
import AvatarPage from "./pages/component/AvatarPage";
import BadgePage from "./pages/component/BadgePage";
import BreadcrumbPage from "./pages/component/BreadcrumbPage";
import ButtonPage from "./pages/component/ButtonPage";
import DropdownPage from "./pages/component/DropdownPage";
import FormPage from "./pages/component/FormPage";
import IconsPage from "./pages/component/IconsPage";
import ModalPage from "./pages/component/ModalPage";
import PaginationPage from "./pages/component/PaginationPage";
import TabsPage from "./pages/component/TabsPage";
import TooltipPage from "./pages/component/TooltipPage";
// Import pages
import Dashboard from "./pages/Dashboard";
import Cart from "./pages/ecommerce/Cart";
import Cart2 from "./pages/ecommerce/Cart2";
import Cart3 from "./pages/ecommerce/Cart3";
import Customers from "./pages/ecommerce/Customers";
import Invoices from "./pages/ecommerce/Invoices";
import Orders from "./pages/ecommerce/Orders";
import Pay from "./pages/ecommerce/Pay";
import Product from "./pages/ecommerce/Product";
import Shop from "./pages/ecommerce/Shop";
import Shop2 from "./pages/ecommerce/Shop2";
import CreditCards from "./pages/finance/CreditCards";
import TransactionDetails from "./pages/finance/TransactionDetails";
import Transactions from "./pages/finance/Transactions";
import Fintech from "./pages/Fintech";
import Inbox from "./pages/Inbox";
import CompanyProfile from "./pages/job/CompanyProfile";
import JobListing from "./pages/job/JobListing";
import JobPost from "./pages/job/JobPost";
import Messages from "./pages/Messages";
import Onboarding from "./pages/Onboarding";
import ResetPassword from "./pages/ResetPassword";
import Account from "./pages/settings/Account";
import Apps from "./pages/settings/Apps";
import Billing from "./pages/settings/Billing";
import Feedback from "./pages/settings/Feedback";
import Notifications from "./pages/settings/Notifications";
import Plans from "./pages/settings/Plans";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import TasksKanban from "./pages/tasks/TasksKanban";
import TasksList from "./pages/tasks/TasksList";
import Changelog from "./pages/utility/Changelog";
import EmptyState from "./pages/utility/EmptyState";
import Faqs from "./pages/utility/Faqs";
import KnowledgeBase from "./pages/utility/KnowledgeBase";
import PageNotFound from "./pages/utility/PageNotFound";
import Roadmap from "./pages/utility/Roadmap";
import PrivateRoute from './PrivateRoute';
import { clearErrorMsg } from './state/errorSlice';
import { clearGlobalMsg } from './state/globalMessageSlice';
import Banner2 from './components/Banner2'

import CitiesList from './pages/settings/Cities';
import CountriesList from './pages/settings/Countries';
import CurrenciesList from './pages/settings/Currencies';
import StatusList from './pages/settings/Status';
import TimeslotsList from './pages/TimeSlots';
import BookingtagsList from './pages/settings/BookingTags';
import PurchaseordersList from './pages/Purchaseorders';
import PackedList from './pages/Packedlist';
import SuppliersList from './pages/Suppliers';
import ShipmentsList from "./pages/Shipments";


function App(props) {
  /*
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change
  */

  useEffect(() => {
    console.log('errorMessage::::: APP.js: ', props.errorMessage)
    if (props.errorMessage && props.errorMessage.length > 0) {
      setTimeout(() => {
        props.clearErrorMsg()
      }, 10000)
    }
  }, [props.errorMessage])

  useEffect(() => {
    console.log('globalMessage::::: APP.js: ', props.globalMessage)
    if (props.globalMessage && props.globalMessage.length > 0) {
      setTimeout(() => {
        props.clearGlobalMsg()
      }, 10000)
    }
  }, [props.globalMessage])

  return (
    <BrowserRouter>
      {props.globalMessage && props.globalMessage.length > 0 && (
        <Banner2 type="success" open='true' className='no-close mb-4 globalmsg'>
          {props.globalMessage}
        </Banner2>
      )}
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/activateuser/:id" element={<ActivateUser />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} fallbackPath="/" />}
        />
        <Route path="/login" element={<Signin />} />
        <Route path="/dashboard/analytics" element={<Analytics />} />
        <Route path="/dashboard/fintech" element={<Fintech />} />
        <Route path="/ecommerce/customers" element={<Customers />} />
        <Route path="/ecommerce/orders" element={<Orders />} />
        <Route path="/ecommerce/invoices" element={<Invoices />} />
        <Route path="/ecommerce/shop" element={<Shop />} />
        <Route path="/ecommerce/shop-2" element={<Shop2 />} />
        <Route path="/ecommerce/product" element={<Product />} />
        <Route path="/ecommerce/cart" element={<Cart />} />
        <Route path="/ecommerce/cart-2" element={<Cart2 />} />
        <Route path="/ecommerce/cart-3" element={<Cart3 />} />
        <Route path="/ecommerce/pay" element={<Pay />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/community/users-tabs" element={<UsersTabs />} />
        <Route path="/community/users-tiles" element={<UsersTiles />} />
        <Route path="/community/profile" element={<Profile />} />
        <Route path="/community/feed" element={<Feed />} />
        <Route path="/community/forum" element={<Forum />} />
        <Route path="/community/forum-post" element={<ForumPost />} />
        <Route path="/community/meetups" element={<Meetups />} />
        <Route path="/community/meetups-post" element={<MeetupsPost />} />
        <Route path="/finance/cards" element={<CreditCards />} />
        <Route path="/finance/transactions" element={<Transactions />} />
        <Route
          path="/finance/transaction-details"
          element={<TransactionDetails />}
        />
        <Route path="/job/job-listing" element={<JobListing />} />
        <Route path="/job/job-post" element={<JobPost />} />
        <Route path="/job/company-profile" element={<CompanyProfile />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/tasks/kanban" element={<TasksKanban />} />
        <Route path="/tasks/list" element={<TasksList />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/settings/account" element={<Account />} />
        <Route path="/settings/notifications" element={<Notifications />} />
        <Route path="/settings/apps" element={<Apps />} />
        <Route path="/settings/plans" element={<Plans />} />
        <Route path="/settings/billing" element={<Billing />} />
        <Route path="/settings/feedback" element={<Feedback />} />
        <Route path="/utility/changelog" element={<Changelog />} />
        <Route path="/utility/roadmap" element={<Roadmap />} />
        <Route path="/utility/faqs" element={<Faqs />} />
        <Route path="/utility/empty-state" element={<EmptyState />} />
        <Route path="/utility/404" element={<PageNotFound />} />
        <Route path="/utility/knowledge-base" element={<KnowledgeBase />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/component/button" element={<ButtonPage />} />
        <Route path="/component/form" element={<FormPage />} />
        <Route path="/component/dropdown" element={<DropdownPage />} />
        <Route path="/component/alert" element={<AlertPage />} />
        <Route path="/component/modal" element={<ModalPage />} />
        <Route path="/component/pagination" element={<PaginationPage />} />
        <Route path="/component/tabs" element={<TabsPage />} />
        <Route path="/component/breadcrumb" element={<BreadcrumbPage />} />
        <Route path="/component/badge" element={<BadgePage />} />
        <Route path="/component/avatar" element={<AvatarPage />} />
        <Route path="/component/tooltip" element={<TooltipPage />} />
        <Route path="/component/accordion" element={<AccordionPage />} />
        <Route path="/component/icons" element={<IconsPage />} />

        <Route
          path="/settings/cities"
          element={<PrivateRoute element={<CitiesList />} fallbackPath="/" />}
        />
        <Route
          path="/settings/countries"
          element={<PrivateRoute element={<CountriesList />} fallbackPath="/" />}
        />
        <Route
          path="/settings/currencies"
          element={<PrivateRoute element={<CurrenciesList />} fallbackPath="/" />}
        />
        <Route
          path="/settings/bookingtags"
          element={<PrivateRoute element={<BookingtagsList />} fallbackPath="/" />}
        />
        <Route
          path="/settings/status"
          element={<PrivateRoute element={<StatusList />} fallbackPath="/" />}
        />
        <Route
          path="/ym"
          element={<PrivateRoute element={<TimeslotsList />} fallbackPath="/" />}
        />
        <Route
          path="/purchaseorders"
          element={<PrivateRoute element={<PurchaseordersList />} fallbackPath="/" />}
        />
        <Route
          path="/packedlist"
          element={<PrivateRoute element={<PackedList />} fallbackPath="/" />}
        />
        <Route
          path="/suppliers"
          element={<PrivateRoute element={<SuppliersList />} fallbackPath="/" />}
        />
        <Route
          path="/shipments"
          element={<PrivateRoute element={<ShipmentsList />} fallbackPath="/" />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}


const mapStateToProps = (state) => ({
  errorMessage: state.error.message || '',
  globalMessage: state.globalMessage.message || '',
})

const mapDispatchToProps = (dispatch) => ({
  clearErrorMsg: (...args) => dispatch(clearErrorMsg(...args)),
  clearGlobalMsg: (...args) => dispatch(clearGlobalMsg(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
