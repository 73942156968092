import React from 'react'
import moment from 'moment'
import { statusColor, getStatusDisplay } from '../../utils/UIUtils'

function PackedlistTableItem(props) {
  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr 
				onClick={(e) => {
					if(e.target.tagName !== 'INPUT') {
            props.handlePageAction('details',props)
					}
				}}
			>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                type="checkbox"
                className="form-checkbox"
                onChange={(e) => props.handleClick(props.id, e.target.checked)}
                checked={props.isChecked}
              />
            </label>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  cursor-pointer">
          <div className="flex items-center hover:text-blue-800 hover:underline ">
            <div className="font-medium ">{props.listitem.packed_list_number}</div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center text-slate-800">
            <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.supplier_name}</div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center text-slate-800">
            <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.purchase_order_number}</div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center text-slate-800">
            <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.purchaser_name}</div>
          </div>
        </td>
				
        {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{getStatusDisplay(props.status)}</div>
        </td> */}
      </tr>
    </tbody>
  )
}

export default PackedlistTableItem
