export const statusColor = (status) => {
  switch (status) {
    case 'active':
      return 'bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400';
    case 'inactive':
      return 'bg-amber-100 dark:bg-amber-400/30 text-amber-600 dark:text-amber-400';
    default:
      return 'bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400';
  }
};

// Define a function to handle status conditions
export const getStatusDisplay = (status) => {
  switch (status) {
    case 'waiting_approval':
      return 'Waiting for Supplier approval';
    case 'expected_shippingdate':
      return 'Waiting for Supplier approval';
    case 'approvedby_supplier':
      return 'Approved by Supplier';
    case 'goods_enroute':
      return 'Goods Enroute';
    case 'goods_received':
      return 'Goods Received';
    case 'goods_accepted':
      return 'Goods Accepted';
    case 'po_complete':
      return 'PO Complete';
    default:
      return status;
  }
};

let usedColors = [];

export const eventColor = (color) => {
    switch (color) {
        case 'sky':
            return 'text-white bg-sky-500';
        case 'indigo':
            return 'text-white bg-indigo-500';
        case 'yellow':
            return 'text-white bg-amber-500';
        case 'emerald':
            return 'text-white bg-emerald-500';
        case 'red':
            return 'text-white bg-rose-400';
        default:
            return '';
    }
};

export const getRandomColor = () => {
    const colors = ['sky', 'indigo', 'yellow', 'emerald', 'red'];
    if (usedColors.length === colors.length) {
        // If all colors have been used, reset usedColors array
        usedColors = [];
    }
    let color;
    do {
        color = colors[Math.floor(Math.random() * colors.length)];
    } while (usedColors.includes(color));
    usedColors.push(color);
    return color;
};
