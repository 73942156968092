import React from 'react'
import { statusColor, getStatusDisplay } from '../../utils/UIUtils'

function SupplierPOListTableItem(props) {
    return (
        <tbody className="text-sm">
            {/* Row */}
            <tr>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.purchase_order}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.created_on}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.expected_delivery_date}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.shipping_destination}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{getStatusDisplay(props.status)}</div>
                </td>
            </tr>
        </tbody>
    )
}

export default SupplierPOListTableItem
