import { createSlice } from "@reduxjs/toolkit"
import { fetchCurrencies, createCurrencyAction } from "./currenciesActions"

const initialState = {
  currenciesList: [],
  loading: false,
}

const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    currenciesList: (state, action) => {
      state.currenciesList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.fulfilled, (state, { payload }) => {
        console.log('fetchCurrencies REDUCER FULLFILLED.....', payload)
        state.currenciesList = payload
        state.loading = false
      })
      .addCase(createCurrencyAction.fulfilled, (state, { payload }) => {
        console.log('createCurrencyAction REDUCER FULLFILLED.....', payload)
        state.loading = false
      })
      .addCase(createCurrencyAction.rejected, (state, { payload }) => {
        state.loading = false
        console.log('inside createCurrencyAction.rejected:: ', state, payload)
      });
  },
})

export default currenciesSlice.reducer;
