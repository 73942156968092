import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import PackedlistTableItem from './PackedlistTableItem';
import { fetchPackedlistOrders } from '../../state/packedlist/packedlistActions';
import PaginationClassic from '../../components/PaginationClassic';
import { Logger } from '../../utils/Logger';
import TableHeader from '../../pages/component/TableHeader';
import { formatDate } from '../../utils/Utils'

const PackedlistTable = ({
  selectedItems,
  handlePageAction,
}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const packedListOrders = useSelector(
    (state) => state.packedlist.packedListOrders
  );
  useEffect(() => {
    Logger.log('PackedlistTable - fetching page - ....', currentPage);
    dispatch(fetchPackedlistOrders(currentPage));
  }, [currentPage]);
  useEffect(() => {
    if (packedListOrders && packedListOrders.dataList) {
			setList(packedListOrders.dataList);
      // Reset isChecked state when data changes
      setIsCheck([]);
      setSelectAll(false);
    }
  }, [packedListOrders]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id, checked) => {
    setSelectAll(false);
    setIsCheck((prevIsCheck) => {
      if (checked) {
        return [...prevIsCheck, id];
      } else {
        return prevIsCheck.filter((item) => item !== id);
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    selectedItems(isCheck);
  }, [isCheck]);

  return (
    <>
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
        <header className="px-5 py-4">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">
            All Packedlist Orders{' '}
            <span className="text-slate-400 dark:text-slate-500 font-medium">
              {packedListOrders ? packedListOrders.totalCount : 0}
            </span>
          </h2>
        </header>
        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            {list.length > 0 ? (
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {/* Table header */}
                <TableHeader
                  columns={[
                    'Packedlist Number',
                    'Supplier Name',
                    'Purchase Order',
                    'Purchaser Name',
                  ]}
                  selectAll={selectAll}
                  handleSelectAll={handleSelectAll}
                />
                {/* Table body */}
                {list.map((listitem) => {
                  return (
                    <PackedlistTableItem
                      key={listitem.id}
                      id={listitem.id}
											listitem={listitem}
                      handleClick={handleClick}
                      handlePageAction={handlePageAction}
                      isChecked={isCheck.includes(listitem.id)}
                    />
                  );
                })}
              </table>
            ) : (
              <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                No Packedlist orders found
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <PaginationClassic
          currentPage={currentPage}
          total={packedListOrders ? packedListOrders.totalCount : 0}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  packedListOrders: state.packedlist.packedListOrders ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  fetchPurchaseorders: (...args) => dispatch(fetchPurchaseorders(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackedlistTable);
