import React, { useState, useEffect } from 'react';

const WeekdayCheckboxes = ({ selectedWeekdays, setSelectedWeekdays, setError }) => {
    const [weekdays, setWeekdays] = useState([
        { name: 'Monday', value: 'Monday', selected: false },
        { name: 'Tuesday', value: 'Tuesday', selected: false },
        { name: 'Wednesday', value: 'Wednesday', selected: false },
        { name: 'Thursday', value: 'Thursday', selected: false },
        { name: 'Friday', value: 'Friday', selected: false },
        { name: 'Saturday', value: 'Saturday', selected: false },
        { name: 'Sunday', value: 'Sunday', selected: false }
    ]);

    // Update selected state based on selectedWeekdays prop
    useEffect(() => {
        const updatedWeekdays = weekdays.map(day => ({
            ...day,
            selected: selectedWeekdays.includes(day.value)
        }));
        setWeekdays(updatedWeekdays);
    }, [selectedWeekdays]);

    const handleCheckboxChange = (index) => {
        const updatedWeekdays = [...weekdays];
        updatedWeekdays[index].selected = !updatedWeekdays[index].selected;
        setWeekdays(updatedWeekdays);

        const selected = updatedWeekdays.filter(day => day.selected).map(day => day.value);
        setSelectedWeekdays(selected);

    };

    return (
        <div className="flex flex-wrap items-center -m-3">
            {weekdays.map((day, index) => (
                <div key={index} className="m-3">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id={day.value}
                            checked={day.selected}
                            onChange={() => handleCheckboxChange(index)}
                            className="mr-1" // Added margin to create space between checkbox and label
                        />
                        <label htmlFor={day.value} className="">{day.name}</label>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WeekdayCheckboxes;
