import authReducer from "./auth/authSlice"
import citiesReducer from "./cities/citiesSlice"
import countriesReducer from "./countries/countriesSlice"
import currenciesReducer from "./currencies/currenciesSlice"
import bookingtagsReducer from "./bookingtags/bookingtagsSlice"
import statusReducer from "./status/statusSlice"
import purchaseordersReducer from "./purchaseorders/purchaseordersSlice"
import packedlistReducer from "./packedlist/packedlistSlice"
import suppliersReducer from "./suppliers/suppliersSlice"
import timeslotsReducer from "./timeslots/timeslotsSlice"
import shipmentsReducer from "./shipments/shipmentsSlice"

import { combineReducers } from "@reduxjs/toolkit"
import dashboardReducer from "./dashboard/dashboardSlice"
import errorReducer from "./errorSlice"
import globalMessageReducer from "./globalMessageSlice"

const appReducer = combineReducers({
  auth: authReducer,
  cities: citiesReducer,
  countries: countriesReducer,
  currencies: currenciesReducer,
  bookingtags: bookingtagsReducer,
  status: statusReducer,
  timeslots: timeslotsReducer,
  shipments: shipmentsReducer,
  dashboard: dashboardReducer,
  purchaseorders: purchaseordersReducer,
  packedlist: packedlistReducer,
  suppliers: suppliersReducer,
  error: errorReducer,
  globalMessage: globalMessageReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'CLEAR_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}
export default rootReducer
