import { createSlice } from "@reduxjs/toolkit"
import { fetchCountries, createCountryAction } from "./countriesActions"

const initialState = {
  countriesList: [],
  loading: false,
}

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    countriesList: (state, action) => {
      state.countriesList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.fulfilled, (state, { payload }) => {
        console.log('fetchCountries REDUCER FULLFILLED.....', payload)
        state.countriesList = payload
        state.loading = false
      })
      .addCase(createCountryAction.fulfilled, (state, { payload }) => {
        console.log('createCountryAction REDUCER FULLFILLED.....', payload)
        state.loading = false
      })
      .addCase(createCountryAction.rejected, (state, { payload }) => {
        state.loading = false
        console.log('inside createCountryAction.rejected:: ', state, payload)
      });
  },
})

export default countriesSlice.reducer;
