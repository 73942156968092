import React from "react";
import { APP_NAME } from "./../config";

function Onboarding04() {
  return (
    <div className="text-center">
      <svg
        className="inline-flex w-16 h-16 fill-current mb-6"
        viewBox="0 0 64 64"
      >
        <circle
          className="text-emerald-100 dark:text-emerald-400/30"
          cx="32"
          cy="32"
          r="32"
        />
        <path
          className="text-emerald-500 dark:text-emerald-400"
          d="m28.5 41-8-8 3-3 5 5 12-12 3 3z"
        />
      </svg>
      <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-8">
        {`Thanks for choosing “${APP_NAME}”`}
      </h1>
      We have sent an onboarding email. Please click on the activation link to
      start using {APP_NAME}.
    </div>
  );
}

export default Onboarding04;
