import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import StatusTableItem from './StatusTableItem'
import { fetchStatus } from "../../../state/status/statusActions"
import PaginationClassic from "../../../components/PaginationClassic"
import { Logger } from '../../../utils/Logger'
import TableHeader from "../../../pages/component/TableHeader"

const StatusTable = ({ 
  selectedItems, 
  handlePageAction, 
  fetchStatus, 
  statusList }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    Logger.log('StatusTable - fetching page - ....', currentPage)
    fetchStatus(currentPage)
  }, [currentPage])

  useEffect(() => {
    setList(statusList.dataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusList]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  }

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <>
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">All Status <span className="text-slate-400 dark:text-slate-500 font-medium">{statusList.totalCount}</span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <TableHeader columns={['Id', 'Name', 'Display Tag', 'Type', 'Status', 'Actions']} selectAll={selectAll} handleSelectAll={handleSelectAll} />        
            {/* Table body */}
            {
              list.map(listitem => {
                return (
                  <StatusTableItem
                    key={listitem.id}
                    id={listitem.id}
                    name={listitem.name}
                    display_tag={listitem.display_tag}
                    type={listitem.type}
                    status={listitem.displaystatus}
                    handleClick={handleClick}
                    handlePageAction={handlePageAction}
                    isChecked={isCheck.includes(listitem.id)}
                  />
                )
              })
            }
          </table>

        </div>
      </div>
    </div>
    <div className="mt-8">
      <PaginationClassic 
        currentPage={currentPage} 
        total={statusList.totalCount}
        handlePageChange={handlePageChange}
      />
    </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  statusList: state.status.statusList ?? [],
})

const mapDispatchToProps = (dispatch) => ({
    fetchStatus: (...args) => dispatch(fetchStatus(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusTable)
