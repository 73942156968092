import { createSlice } from "@reduxjs/toolkit"
import { fetchTimeslots, createTimeslotAction } from "./timeslotsActions"

const initialState = {
  timeslotsList: [],
  loading: false,
}

const timeslotsSlice = createSlice({
  name: "timeslots",
  initialState,
  reducers: {
    timeslotsList: (state, action) => {
      state.timeslotsList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeslots.fulfilled, (state, { payload }) => {
        console.log('fetchTimeslots REDUCER FULLFILLED.....', payload)
        state.timeslotsList = payload
        state.loading = false
      })
      .addCase(createTimeslotAction.fulfilled, (state, { payload }) => {
        console.log('createTimeslotAction REDUCER FULLFILLED.....', payload)
        state.loading = false
      })
      .addCase(createTimeslotAction.rejected, (state, { payload }) => {
        state.loading = false
        console.log('inside createTimeslotAction.rejected:: ', state, payload)
      });
  },
})

export default timeslotsSlice.reducer;
