import { registerUser, userLogin, selfRegisterUser, activateUser, } from "./authActions";
import { Cookies } from 'react-cookie';
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

//Added for testing
// initialize userToken from cookies
const cookies = new Cookies();
const userToken = cookies.get("userToken") || null;

const initialState = {
  isAuthenticated: false,
  loading: false,
  userInfo: null,
  userToken: "",
  success: false,
};

const persistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading", "success","error"], // Properties to exclude from persistence
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      cookies.remove("userToken"); // remove token cookie
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state = {...initialState};
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.userInfo = payload;
        state.userToken = payload.token;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(selfRegisterUser.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(selfRegisterUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(selfRegisterUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
      })
      .addCase(activateUser.pending, (state, { payload }) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(activateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(activateUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
      });
  },
});

const persistedReducer = persistReducer(persistConfig, authSlice.reducer);
export const { logout, setCredentials } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export const selectUserToken = (state) => state.auth.userInfo.token;
export default authSlice.reducer;
