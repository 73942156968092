import { Link } from "react-router-dom";
import React from "react";
import AppLogo from "../images/applogo.png";
const Logo = () => {
  return (
    <Link className="block" to="/">
    <div style={{ width: "125px" }}>
      <img
        className="object-cover object-center w-full h-full"
        src={AppLogo}
        alt="Logo"
      />
    </div>
    </Link>
  );
};

export default Logo;
