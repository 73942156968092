import React from 'react'
import { statusColor, getStatusDisplay } from '../../utils/UIUtils'

function PLItemListTableItem(props) {
    return (
        <tbody className="text-sm">
            {/* Row */}
            <tr>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.item_number}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.purchase_order_number}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.gtin_number}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.supplier_item_number}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.item_name}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.quantity}</div>
                    </div>
                </td>
								{ (props.postatus !== 'waiting_approval') && (
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.received_quantity}</div>
                    </div>
                </td>
								)}
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.listitem.unit_of_measure_code}</div>
                    </div>
                </td>
                {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{getStatusDisplay(props.status)}</div>
                </td> */}
            </tr>
        </tbody>
    )
}

export default PLItemListTableItem
