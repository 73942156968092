import { Navigate, Route, Routes } from "react-router-dom";

// PrivateRoute.js
import React from "react";
import { selectAuth } from "./state/auth/authSlice";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element, fallbackPath }) => {
  const { isAuthenticated } = useSelector(selectAuth);
  console.log("isAuthenticated::::", isAuthenticated, element);
  return isAuthenticated ? (
    <Routes>
      <Route path="/*" element={element} />
    </Routes>
  ) : (
    <Navigate to={fallbackPath} replace />
  );
};

export default PrivateRoute;
