import { createSlice } from "@reduxjs/toolkit"
import { fetchShipments, createShipmentTimeslotAction, fetchTimeSlots, fetchShipmentTimeSlots, fetchShipmentDetails } from "./shipmentsActions"

const initialState = {
    shipmentsList: [],
    timeSlots: [],
    shipmentTimeSlots: [],
    shipmentDetails: [],
    loading: false,
}

const shipmentsSlice = createSlice({
    name: "shipments",
    initialState,
    reducers: {
        shipmentsList: (state, action) => {
            state.shipmentsList = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShipments.fulfilled, (state, { payload }) => {
                console.log('fetchShipments REDUCER FULLFILLED.....', payload)
                state.shipmentsList = payload
                state.loading = false
            })
            .addCase(fetchTimeSlots.fulfilled, (state, { payload }) => {
                console.log('fetchTimeSlots REDUCER FULLFILLED.....', payload)
                state.timeSlots = payload
                state.loading = false
            })
            .addCase(fetchShipmentDetails.fulfilled, (state, { payload }) => {
                console.log('fetchShipmentDetails REDUCER FULLFILLED.....', payload)
                state.shipmentDetails = payload
                state.loading = false
            })
            .addCase(fetchShipmentTimeSlots.fulfilled, (state, { payload }) => {
                console.log('fetchShipmentTimeSlots REDUCER FULLFILLED.....', payload)
                state.shipmentTimeSlots = payload
                state.loading = false
            })
            .addCase(createShipmentTimeslotAction.fulfilled, (state, { payload }) => {
                console.log('createShipmentTimeslotAction REDUCER FULLFILLED.....', payload)
                state.loading = false
            })
            .addCase(createShipmentTimeslotAction.rejected, (state, { payload }) => {
                state.loading = false
                console.log('inside createShipmentTimeslotAction.rejected:: ', state, payload)
            });
    },
})

export default shipmentsSlice.reducer;
