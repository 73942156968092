import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import Banner2 from './../../../components/Banner2'

import { createStatusAction, updateStatusAction } from "./../../../state/status/statusActions"

function StatusForm(props) {
    const [formData, setFormData] = useState({
        staus_name: '',
        display_tag: '',
        type: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.editMode) {
            // If in edit mode, dispatch updateCountryAction with individual parameters
            props.updateStatusAction({ id: props.data.id, ...formData });
        } else {
            // If not in edit mode, dispatch createCountryAction with formData
            props.createStatusAction(formData);
        }    
    }

    useEffect(() => {
        if (props.globalMessage && props.globalMessage.length > 0) {
            setFormData({ status_name: '', display_tag: '', type: '' });
            props.handlePageAction('list')
        }
    }, [props.error, props.globalMessage])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data) {
            setFormData({ ...formData, 
                status_name: props.data.status_name,
                display_tag: props.data.display_tag,
                type: props.data.type 
            })
        }
    }, [props.data]);    

    return (
        <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">{props.editMode ? 'Update' : 'Add'} status</h2>
            {props.error && props.error?.length > 0 && (
                <Banner2 type="error" open='true' className='no-close mb-4'>
                    {props.error}
                </Banner2>
            )}
            <form onSubmit={handleSubmit}>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                        Status Name <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="status_name"
                        name="status_name"
                        className="form-input w-full"
                        type="text"
                        value={formData.status_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Display Tag
                    </label>
                    <input
                        id="display_tag"
                        name="display_tag"
                        className="form-input w-full"
                        type="text"
                        value={formData.display_tag}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Type
                    </label>
                    <input
                        id="type"
                        name="type"
                        className="form-input w-full"
                        type="text"
                        value={formData.type}
                        onChange={handleChange}                        
                    />
                </div>
                <div className="flex items-center justify-end">
                    {props.editMode && (
                        <button
                            type="button"
                            className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                            onClick={() => {
                                props.handlePageAction('list')
                            }}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-1.5"
                    >
                        {props.editMode ? 'Update' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = (state) => ({
    // Map your state to props
    loadingStatus: state.status.loading,
    error: state.error.message,
    globalMessage: state.globalMessage.message
})

const mapDispatchToProps = (dispatch) => ({
    createStatusAction: (...args) => dispatch(createStatusAction(...args)),
    updateStatusAction: (...args) => dispatch(updateStatusAction(...args)),
})


// Connect your component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(StatusForm)
