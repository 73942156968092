import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from '../../config'
import { setErrorMsg } from './../errorSlice'
import { setGlobalMsg } from './../globalMessageSlice'
import { Logger } from '../../utils/Logger'

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = cookies.get("userToken") || null;
export const fetchCities = createAsyncThunk(
  "cities/list",
  async (page, { rejectWithValue }) => {
    try {
      Logger.log(
        "state/action - fetchCities - page:" + page + ", token:",
        token
      );
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/city";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("state/action - fetchCities - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchCities - Zones DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCityAction = createAsyncThunk(
  "cities/create",
  async ({ city }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      // configure header's Content-Type as JSON

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - createCityAction ----", token);
      const response = await axios.post(
        `${backendURL}/city/create`,
        { city_name: city },
        config
      );
      const data = response.data
      Logger.log("state/action - city insert DATA.....:", data);
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - cities - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCityAction = createAsyncThunk(
  'cities/update',
  async ({ id, city }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
      Logger.log('state/action - updateCityAction ----', token, id, city)

      const response = await axios.patch(
        `${backendURL}/city/update/${id}`,
        { city },
        config
      )
      const data = response.data
      Logger.log('state/action - updateCityAction - CITY update DATA.....:', response.status === 201, response, data)
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data
    } catch (error) {
      // return custom error message from API if any
      console.log('ERROR::::', error)
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message)
      } else {
        dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message)
      }
    }
  }
);
