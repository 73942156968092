import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSupplierDetails, fetchSupplierAddresses, fetchSupplierPOList } from './../../state/suppliers/suppliersActions';
import { Logger } from '../../utils/Logger'
import SupplierAddressTableItem from './SupplierAddressTableItem'
import SupplierPOListTableItem from './SupplierPOListTableItem'
import TableHeader from "../../pages/component/TableHeader"
import { formatDate } from '../../utils/Utils'
const SupplierDetailsPage = ({
    data,
    fetchSupplierDetails,
    fetchSupplierAddresses,
    fetchSupplierPOList,
    supplierDetails,
    supplierAddresses,
    supplierPOList }) => {
    const [details, setSupplierDetails] = useState([]);
    const [addresslist, setSupplierAddresses] = useState([]);
    const [polist, setSupplierPOList] = useState([]);

    useEffect(() => {
        Logger.log('SupplierDetailsPage - fetching page - ....', data);
        if (supplierDetails) {
            setSupplierDetails(supplierDetails);
        }
        if (supplierAddresses && supplierAddresses.addresses) {
            setSupplierAddresses(supplierAddresses.addresses);
        }
        if (supplierPOList && supplierPOList.dataList) {
            setSupplierPOList(supplierPOList.dataList);
        }
    }, [supplierDetails, supplierAddresses, supplierPOList]);

    useEffect(() => {
        if (data && data.id) {
            Logger.log('Supplier ID:', data.id);
            fetchSupplierDetails({ id: data.id });
            fetchSupplierAddresses({ id: data.id }); // Fetch supplier addresses
            fetchSupplierPOList({ id: data.id }); // Fetch supplier PO list
        }
    }, [data.id, fetchSupplierDetails, fetchSupplierAddresses, fetchSupplierPOList]);

    // Log supplierDetails whenever it changes
    useEffect(() => {
        console.log('Supplier Details:', supplierDetails);
    }, [supplierDetails]);

    return (
        <div className="grow">
            {/* Panel body */}
            <div className="p-6 space-y-6">
                {/* Supplier Details */}
                <section>
                    <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Supplier Details</h3>
                    <ul>
                        <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200 dark:border-slate-700">
                            <div className="text-sm text-slate-800 dark:text-slate-100 font-medium">Source Supplier ID: {details.external_supplier_id}</div>
                        </li>
                        <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200 dark:border-slate-700">
                            <div className="text-sm text-slate-800 dark:text-slate-100 font-medium">Supplier Number: {details.supplier_number}</div>
                        </li>
                        <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200 dark:border-slate-700">
                            <div className="text-sm text-slate-800 dark:text-slate-100 font-medium">Supplier Name: {details.supplier_name}</div>
                        </li>
                        <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200 dark:border-slate-700">
                            <div className="text-sm text-slate-800 dark:text-slate-100 font-medium">Email Id: {details.email_id}</div>
                        </li>
                        <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200 dark:border-slate-700">
                            <div className="text-sm text-slate-800 dark:text-slate-100 font-medium">Address: {details.supplier_address_line1} {details.supplier_address_line2} {details.supplier_city} {details.supplier_state} {details.supplier_country}</div>
                        </li>
                        <li className="md:flex md:justify-between md:items-center py-3 border-b border-slate-200 dark:border-slate-700">
                            <div className="text-sm text-slate-800 dark:text-slate-100 font-medium">Contact Name: {details.contact_name}</div>
                        </li>
                    </ul>
                </section>
                {/* Supplier Addresses */}
                <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Supplier Addresses</h3>
                <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
                    <div>
                        {/* Table */}
                        <div className="overflow-x-auto">
                            {addresslist.length > 0 ? (
                                <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                                    {/* Table header */}
                                    <TableHeader columns={['Id', 'Address Title', 'Address Type', 'Address', 'Postcode', 'Zone', 'City', 'Contact Person', 'Telephone', 'Status']} showCheckbox={false} />
                                    {/* Table body */}
                                    {
                                        addresslist.map(listaddressitem => {
                                            return (
                                                <SupplierAddressTableItem
                                                    key={listaddressitem.id}
                                                    id={listaddressitem.id}
                                                    address_title={listaddressitem.address_title}
                                                    address_type={listaddressitem.address_type}
                                                    address={listaddressitem.address}
                                                    pincode={listaddressitem.pincode}
                                                    zone={listaddressitem.zone}
                                                    city={listaddressitem.city}
                                                    reference={listaddressitem.reference}
                                                    telephone={listaddressitem.telephone}
                                                    status={listaddressitem.status}
                                                />
                                            )
                                        })
                                    }
                                </table>
                            ) : (
                                <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                                    No Supplier Addresses found
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                {/* Purchase Orders */}
                <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Purchase Orders</h3>
                <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
                    <div>
                        {/* Table */}
                        <div className="overflow-x-auto">
                            {polist.length > 0 ? (
                                <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                                    {/* Table header */}
                                    <TableHeader columns={['Purchase Order', 'PO Date', 'Expected Delivery Date', 'Shipping Destination', 'Status']} showCheckbox={false} />
                                    {/* Table body */}
                                    {
                                        polist.map(listpoitem => {
                                            return (
                                                <SupplierPOListTableItem
                                                    key={listpoitem.id}
                                                    purchase_order={listpoitem.purchase_order}
                                                    supplier_name={listpoitem.supplier_name}
                                                    created_on={formatDate(listpoitem.created_on)}
                                                    expected_delivery_date={formatDate(listpoitem.expected_delivery_date)}
                                                    shipping_destination={listpoitem.shipping_destination}
                                                    status={listpoitem.status}
                                                />
                                            )
                                        })
                                    }
                                </table>
                            ) : (
                                <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                                    No Purchase Orders found
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    supplierDetails: state.suppliers.supplierDetails,
    supplierAddresses: state.suppliers.supplierAddresses,
    supplierPOList: state.suppliers.supplierPOList,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSupplierDetails: (id) => dispatch(fetchSupplierDetails(id)),
    fetchSupplierAddresses: (id) => dispatch(fetchSupplierAddresses(id)),
    fetchSupplierPOList: (id) => dispatch(fetchSupplierPOList(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetailsPage);
