import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import SuppliersTableItem from './SuppliersTableItem'
import { fetchSuppliers } from "../../state/suppliers/suppliersActions"
import PaginationClassic from "../../components/PaginationClassic"
import { Logger } from '../../utils/Logger'
import TableHeader from "../../pages/component/TableHeader"

const SuppliersTable = ({
    selectedItems,
    handlePageAction,
    fetchSuppliers,
    suppliersList }) => {
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        Logger.log('SuppliersTable - fetching page - ....', currentPage);
        fetchSuppliers(currentPage);
    }, [currentPage, fetchSuppliers]);

    useEffect(() => {
        if (suppliersList && suppliersList.dataList) {
            setList(suppliersList.dataList);
        }
    }, [suppliersList]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setIsCheck(list.map(li => li.id));
        if (selectAll) {
            setIsCheck([]);
        }
    }

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    useEffect(() => {
        selectedItems(isCheck);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheck]);

    return (
        <>
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
                <header className="px-5 py-4">
                    <h2 className="font-semibold text-slate-800 dark:text-slate-100">All Suppliers <span className="text-slate-400 dark:text-slate-500 font-medium">{suppliersList.totalCount}</span></h2>
                </header>
                <div>

                    {/* Table */}
                    <div className="overflow-x-auto">
                        {list.length > 0 ? (
                            <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                                {/* Table header */}
                                <TableHeader columns={['Supplier Id', 'Supplier Name', 'Email Id', 'Address', 'Postcode', 'City', 'Status', 'Actions']} selectAll={selectAll} handleSelectAll={handleSelectAll} />
                                {/* Table body */}
                                {
                                    list.map(listitem => {
                                        return (
                                            <SuppliersTableItem
                                                key={listitem.id} 
                                                id={listitem.id}                                               
                                                listitem={listitem}                                               
                                                supplier_number={listitem.supplier_number}
                                                supplier_name={listitem.supplier_name}
                                                email_id={listitem.email_id}
                                                address={listitem.address}
                                                pincode={listitem.pincode}
                                                city={listitem.city}
                                                status={listitem.status}
                                                handleClick={handleClick}
                                                handlePageAction={handlePageAction}
                                                isChecked={isCheck.includes(listitem.id)}
                                            />
                                        )
                                    })
                                }
                            </table>
                        ) : (
                            <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                                No Suppliers found
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <PaginationClassic
                    currentPage={currentPage}
                    total={suppliersList.totalCount}
                    handlePageChange={handlePageChange}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    suppliersList: state.suppliers.suppliersList ?? [],
})

const mapDispatchToProps = (dispatch) => ({
    fetchSuppliers: (...args) => dispatch(fetchSuppliers(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersTable)
