import { createSlice } from "@reduxjs/toolkit"
import { fetchBookingtags, createBookingtagAction } from "./bookingtagsActions"

const initialState = {
    bookingtagsList: [],
    loading: false,
}

const bookingtagsSlice = createSlice({
    name: "bookingtags",
    initialState,
    reducers: {
        bookingtagsList: (state, action) => {
            state.bookingtagsList = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBookingtags.fulfilled, (state, { payload }) => {
                console.log('fetchBookingtags REDUCER FULLFILLED.....', payload)
                state.bookingtagsList = payload
                state.loading = false
            })
            .addCase(createBookingtagAction.fulfilled, (state, { payload }) => {
                console.log('createBookingtagAction REDUCER FULLFILLED.....', payload)
                state.loading = false
            })
            .addCase(createBookingtagAction.rejected, (state, { payload }) => {
                state.loading = false
                console.log('inside createBookingtagAction.rejected:: ', state, payload)
            });
    },
})

export default bookingtagsSlice.reducer;
