import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { fetchPurchaseorderDetails } from "./../../state/purchaseorders/purchaseordersActions";
import { Logger } from "../../utils/Logger";
import TableHeader from "../../pages/component/TableHeader";
import { formatDate } from "../../utils/Utils";
import POItemListTableItem from "./POItemListTableItem";

const PurchaseorderDetailsPage = ({
  data,
  fetching,
  fetchPurchaseorderDetails,
  purchaseorderDetails,
}) => {
  const [details, setPurchaseorderDetails] = useState([]);
  const [itemsdetails, setItemDetails] = useState([]);

  useEffect(() => {
    if (purchaseorderDetails) {
      setPurchaseorderDetails(purchaseorderDetails.dataValues);
      setItemDetails(purchaseorderDetails.itemsList);
    }
  }, [purchaseorderDetails]);

  useEffect(() => {
    if (data && data.id) {
      Logger.log("Purchaseorder ID:", data.id);
      fetchPurchaseorderDetails({ id: data.id });
    }
  }, [data.id, fetchPurchaseorderDetails]);

	const getTableColumns = (status) => {
		let columns = [
								"Item Number",
                "GTin Number",
                "Supplier Item Number",
                "Item Name",
                "Quantity",
                "Unit"
							]
		if(status !== 'waiting_approval') {
			let index = columns.length > 0 ? columns.length - 1 : 0;
			columns.splice(index, 0, "Cnf. Quantity")
		}	
		return columns
	}

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
				{ fetching && (
					<div className="">Loading....</div>
				)}
				{ !details && (
					<div className="">No details found for this purchase order number.</div>
				)}

				{ (!fetching && details) && (
				<>
        {/* Purchaseorder Details */}
        <section>
          <div className="grid grid-cols-12 gap-6">
            {/* Card 1 */}
            <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700">
              {/* Card content */}
              <div className="flex flex-col h-full p-5">
                <div className="grow">
                  <header className="flex items-center mb-4">
                    <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-indigo-500 to-indigo-300 mr-3">
                      <svg
                        className="w-10 h-10 fill-current text-white"
                        viewBox="0 0 40 40"
                      >
                        <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold">
                      Purchase order details
                    </h3>
                  </header>
                  <div className="text-sm">
                    <div>
                      {details.shipto_address_line1}{" "}
                      {details.shipto_address_line2}
                    </div>
                    <div>
                      {details.shipto_city} {details.shipto_state}
                    </div>
                    <div>
                      {details.shipto_pincode} {details.shipto_country}
                    </div>
                    <div>
                      Order Date:{" "}
                      {details.purchase_order_date &&
                        moment(details.purchase_order_date).format(
                          "YYYY-MM-DD"
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Card 2 */}
            <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700">
              {/* Card content */}
              <div className="flex flex-col h-full p-5">
                <div className="grow">
                  <header className="flex items-center mb-4">
                    <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-emerald-500 to-emerald-300 mr-3">
                      <svg
                        className="w-10 h-10 fill-current text-white"
                        viewBox="0 0 40 40"
                      >
                        <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold">
                      Supplier details
                    </h3>
                  </header>
                  <div className="text-sm">
                    <div>Name: {details.supplier_name}</div>
                    <div>
                      {details.supplier_address_line1}{" "}
                      {details.supplier_address_line2}
                    </div>
                    <div>
                      {details.supplier_city} {details.supplier_state}
                    </div>
                    <div>
                      {details.supplier_pincode} {details.supplier_country}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Purchase Order items */}
        {details && itemsdetails && itemsdetails.length > 0 ? (
          <>
            <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
              Purchase Order Items
            </h3>
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
              <div>
                <div className="overflow-x-auto">
                  <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                    {/* Table header */}
                    <TableHeader
                      columns={getTableColumns(details.status)}
                      showCheckbox={false}
                    />
                    {itemsdetails.map((listpoitem) => {
                      return (
                        <POItemListTableItem
                          key={listpoitem.id}
                          postatus={details.status}
                          listpoitem={listpoitem}
                          created_on={formatDate(listpoitem.created_on)}
                          expected_delivery_date={formatDate(
                            listpoitem.expected_delivery_date
                          )}
                          status={listpoitem.status}
                        />
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>No purchase order items</div>
        )}
				</>
				)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetching: state.purchaseorders.loading,
  purchaseorderDetails: state.purchaseorders.purchaseorderDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPurchaseorderDetails: (id) => dispatch(fetchPurchaseorderDetails(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseorderDetailsPage);
