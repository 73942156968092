import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import Banner2 from './../../../components/Banner2'
import { createCityAction, updateCityAction } from "./../../../state/cities/citiesActions"

function CitiesForm(props) {
    const [formData, setFormData] = useState({
        city: '',
    });


    const handleSubmit = (e) => {
        e.preventDefault();

        if (props.editMode) {
            // If in edit mode, dispatch editData action
            props.updateCityAction({ id: props.data.id, ...formData });
        } else {
            // If not in edit mode, dispatch addData action
            props.createCityAction(formData)
        }
    }

    useEffect(() => {
        if (props.globalMessage && props.globalMessage.length > 0) {
            setFormData({ city: '' })
            props.handlePageAction('list')
        }
    }, [props.error, props.globalMessage])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data) {
            setFormData({ ...formData, city: props.data.city })
        }
    }, [props.data]);


    return (
        <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">{props.editMode ? 'Update' : 'Add'} city</h2>
            {props.error && props.error?.length > 0 && (
                <Banner2 type="error" open='true' className='no-close mb-4'>
                    {props.error}
                </Banner2>
            )}
            <form onSubmit={handleSubmit}>
                <div className="space-y-4 mb-8">
                    <input
                        id="city"
                        name="city"
                        className="form-input w-full"
                        type="text"
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex items-center justify-end">
                    {props.editMode && (
                        <button
                            type="button"
                            className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                            onClick={() => {
                                props.handlePageAction('list')
                            }}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-1.5"
                    >
                        {props.editMode ? 'Update' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    );
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = (state) => ({
    // Map your state to props
    loadingStatus: state.cities.loading,
    error: state.error.message,
    globalMessage: state.globalMessage.message
})

const mapDispatchToProps = (dispatch) => ({
    createCityAction: (...args) => dispatch(createCityAction(...args)),
    updateCityAction: (...args) => dispatch(updateCityAction(...args)),
});

// Connect your component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(CitiesForm);
