// globalMessageSlice.js
import { createSlice } from '@reduxjs/toolkit';

const globalMessageSlice = createSlice({
  name: 'globalMessage',
  initialState: {
    message: null,
  },
  reducers: {
    setGlobalMsg: (state, action) => {
      console.log('inside setGlobalMsg method: state:', state, action)
      state.message = action.payload;
    },
    clearGlobalMsg: (state) => {
      state.message = null;
    },
  },
});

export const { setGlobalMsg, clearGlobalMsg } = globalMessageSlice.actions;
export const selectGlobalMessage = (state) => state.globalMessage.message;

export default globalMessageSlice.reducer;
