import { createSlice } from "@reduxjs/toolkit"
import { fetchPurchaseorders, fetchPurchaseorderDetails, approvePurchaseOrders, createPOAction, confirmPurchaseOrder } from "./purchaseordersActions"

const initialState = {
    purchaseordersList: [],
    purchaseorderDetails: {},
    loading: false,
}

const purchaseordersSlice = createSlice({
    name: "purchaseorders",
    initialState,
    reducers: {
        purchaseordersList: (state, action) => {
            state.purchaseordersList = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPurchaseorders.fulfilled, (state, { payload }) => {
                console.log('fetchPurchaseorders REDUCER FULLFILLED.....', payload)
                state.purchaseordersList = payload
                state.loading = false
            })
            .addCase(fetchPurchaseorderDetails.fulfilled, (state, { payload }) => {
                console.log('fetchPurchaseorderDetails REDUCER FULLFILLED.....', payload)
                state.purchaseorderDetails = payload
                state.loading = false
            })
            .addCase(approvePurchaseOrders.fulfilled, (state, { payload }) => {
                console.log('approvePurchaseOrders REDUCER FULLFILLED.....', payload)
                state.purchaseordersList = payload;
                state.loading = false
            })
            .addCase(createPOAction.fulfilled, (state, { payload }) => {
                console.log('createPOAction REDUCER FULLFILLED.....', payload)
                state.loading = false
            })
            .addCase(createPOAction.rejected, (state, { payload }) => {
                console.log('createPOAction REDUCER REJECTED.....', payload)
                state.loading = false
            })
            //confirmPurchaseOrder
            .addCase(confirmPurchaseOrder.fulfilled, (state, { payload }) => {
                console.log('confirmPurchaseOrder REDUCER FULLFILLED.....', payload)
                state.purchaseorderDetails = payload
                state.loading = false
            })
            .addCase(confirmPurchaseOrder.rejected, (state, { payload }) => {
                console.log('confirmPurchaseOrder REDUCER REJECTED.....', payload)
                state.loading = false
            })
    },
})

export const { setLoading } = purchaseordersSlice.actions;
export default purchaseordersSlice.reducer;
