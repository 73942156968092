import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";
import { setGlobalMsg } from './../globalMessageSlice'
import { setErrorMsg } from "./../errorSlice";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = cookies.get("userToken") || null;

export const fetchCountries = createAsyncThunk(
  "countries/list",
  async (page, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/country";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("state/action - fetchCountries - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchCountries - Countries DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCountryAction = createAsyncThunk(
  "countries/create",
  async ({ country, iso_code, currency }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      // configure header's Content-Type as JSON

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - createCountryAction ----", token);
      const response = await axios.post(
        `${backendURL}/country/create`,
        { country_name: country, iso_code: iso_code, currency: currency },
        config
      );
      const data = response.data
      Logger.log("state/action - country insert DATA.....:", data);
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - countries - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCountryAction = createAsyncThunk(
  'countries/update',
  async ({ id, country, iso_code, currency }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
      Logger.log('state/action - updateCountryAction ----', token, id, country)
      const updateData = {};

      if (country) updateData.name = country;
      if (iso_code) updateData.iso_code = iso_code;
      if (currency) updateData.currency = currency;

      const response = await axios.patch(
        `${backendURL}/country/update/${id}`,
        updateData,
        config
      )
      const data = response.data
      Logger.log('state/action - updateCountryAction - COUNTRY update DATA.....:', response.status === 201, response, data)
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data
    } catch (error) {
      // return custom error message from API if any
      console.log('ERROR::::', error)
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message)
      } else {
        dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message)
      }
    }
  }
)

