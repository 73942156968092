import React from "react";
import CommonPageLayout from "./component/CommonPageLayout";

const NoSidebarPageLayout = ({ children }) => {
  return (
    <CommonPageLayout pageTitle="">
      <div className="flex flex-col md:flex-row md:-mr-px">        
        {children}
      </div>
    </CommonPageLayout>
  );
};

export default NoSidebarPageLayout;
