import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";
import { setGlobalMsg } from './../globalMessageSlice'
import { setErrorMsg } from "./../errorSlice";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = cookies.get("userToken") || null;

export const fetchBookingtags = createAsyncThunk(
  "bookingtags/list",
  async (page, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      var url = backendURL + "/bookingtags";
      if (page) {
        url += "?page=" + page;
      }
      Logger.log("state/action - fetchBookingtags - url:", url);

      const { data } = await axios.get(url, config);
      Logger.log("state/action - fetchBookingtags - Bookingtags DATA:", data);
      return data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createBookingtagAction = createAsyncThunk(
  "status/create",
  async ({ tag_name, email_id, littera_no }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setErrorMsg(""));
      // configure header's Content-Type as JSON

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      Logger.log("state/action - createBookingtagAction ----", token);
      const response = await axios.post(
        `${backendURL}/bookingtags/create`,
        { tag_name: tag_name, email_id: email_id, littera_no: littera_no },
        config
      );
      const data = response.data
      Logger.log("state/action - bookingtag insert DATA.....:", data);
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data;
    } catch (error) {
      Logger.log(
        "state/action - bookingtags - Error:",
        error,
        error.response && error.response.data.message
      );
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message));
        //setErrorMsg(error.response.data.message)
        return rejectWithValue(error.response.data.message);
      } else {
        dispatch(setErrorMsg(error.message));
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateBookingtagAction = createAsyncThunk(
  'bookingtags/update',
  async ({ id, tag_name, email_id, littera_no }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
      Logger.log('state/action - updateBookingtagAction ----', token, id, tag_name)
      const updateData = {};

      if (tag_name) updateData.tag_name = tag_name;
      if (email_id) updateData.email_id = email_id;
      if (littera_no) updateData.littera_no = littera_no;

      const response = await axios.patch(
        `${backendURL}/bookingtags/update/${id}`,
        updateData,
        config
      )
      const data = response.data
      Logger.log('state/action - updateBookingtagAction - BOOKINGTAG update DATA.....:', response.status === 201, response, data)
      if (response.status === 201) {
        dispatch(setGlobalMsg(data.message))
      }
      return data.data
    } catch (error) {
      // return custom error message from API if any
      console.log('ERROR::::', error)
      if (error.response && error.response.data.message) {
        dispatch(setErrorMsg(error.response.data.message))
        return rejectWithValue(error.response.data.message)
      } else {
        dispatch(setErrorMsg(error.message))
        return rejectWithValue(error.message)
      }
    }
  }
)

