import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import Banner2 from './../../components/Banner2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import { fetchTimeSlots, fetchShipmentDetails, createShipmentTimeslotAction } from "./../../state/shipments/shipmentsActions"

function ShipmentTimeSlotForm(props) {
    const [timeslots, setTimeSlots] = useState([]);
    const [shipmentdetails, setShipmentDetails] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [shipmentId, setShipmentId] = useState('');
    const [shipmentType, setShipmentType] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let shipmentDetails = await props.fetchShipmentDetails({ id: props.data.id });
                shipmentDetails = shipmentDetails.payload.shipment
                setShipmentId(shipmentDetails.id);
                setShipmentDetails(shipmentDetails);
            } catch (error) {
                console.error('Error fetching Shipment details:', error);
            }
        };
        fetchData();
    }, [props.data.id, props.fetchShipmentDetails]);

    useEffect(() => {
        if (shipmentdetails) {
            setFormData({
                ...formData,
                po_number: shipmentdetails.po_number,
                supplier_name: shipmentdetails.supplier_name,
                supplier_location: shipmentdetails.supplier_address,
                delivery_location: shipmentdetails.shipping_destination,
                transport_company: shipmentdetails.transport_company,
            });
        }
    }, [shipmentdetails]);

    const [formData, setFormData] = useState({
        po_number: '',
        supplier_name: '',
        supplier_location: '',
        delivery_location: '',
        transport_company: '',
        shipment_type: '',
        shipment_date: '',
        time_slot: '',
        truck_number: '',
        trailer_number: '',
        driver_name: '',
        driver_phone: '',
        notes: '',
        email_id: '',
    });

    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = async date => {
        // Extracting year, month, and day components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Formatting the date to YYYY-MM-DD format
        const formattedDate = `${year}-${month}-${day}`
        setSelectedDate(date);
        let slotDetails = await props.fetchTimeSlots({ shipment_date: formattedDate });
        slotDetails = slotDetails.payload.result

        // Extract opening_time, closing_time, and slot_duration from slotDetails
        const { opening_time, closing_time, slot_duration } = slotDetails;

        // Generate time slots
        const generatedTimeSlots = generateTimeSlots(opening_time, closing_time, slot_duration);
        // Set the time slots
        setTimeSlots(generatedTimeSlots);
        setFormData({ ...formData, shipment_date: formattedDate });
    };

    const generateTimeSlots = (openingTimeStr, closingTimeStr, slotDuration) => {
        const timeSlots = [];
        const openingTimeParts = openingTimeStr.split(':').map(part => parseInt(part, 10));
        const closingTimeParts = closingTimeStr.split(':').map(part => parseInt(part, 10));

        let currentTime = new Date();
        currentTime.setHours(openingTimeParts[0], openingTimeParts[1], openingTimeParts[2]); // Set the opening time

        let closingTime = new Date();
        closingTime.setHours(closingTimeParts[0], closingTimeParts[1], closingTimeParts[2]); // Set the closing time

        // Loop until the currentTime exceeds the closingTime
        while (currentTime < closingTime) {
            let slotEndTime = new Date(currentTime.getTime() + slotDuration * 60000); // Convert slot duration from minutes to milliseconds

            // Ensure the slot end time does not exceed the closing time
            if (slotEndTime > closingTime) {
                slotEndTime = closingTime;
            }

            timeSlots.push({
                startTime: currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                endTime: slotEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            });

            currentTime = new Date(slotEndTime); // Move to the end of the current slot for the next iteration
        }

        return timeSlots;
    };

    const handleTimeSlotChange = e => {
        const selectedSlot = e.target.value;
        setSelectedTimeSlot(selectedSlot);
        setFormData({ ...formData, time_slot: selectedSlot });
    };


    const handleShipmentTypeChange = (e) => {
        setShipmentType(e.target.value);
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: newValue, shipment_type: value });
        setShipmentType(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!shipmentType) {
            // No radio button is selected, handle validation error
            alert('Please select a shipment type!');
            return;
        }
        // Include shipment_id in form data
        const formDataWithShipmentId = {
            ...formData,
            shipment_id: shipmentId
        };
        props.createShipmentTimeslotAction(formDataWithShipmentId);
    };

    useEffect(() => {
        if (props.globalMessage && props.globalMessage.length > 0) {
            setFormData({
                po_number: '',
                supplier_name: '',
                supplier_location: '',
                delivery_location: '',
                transport_company: '',
                shipment_date: '',
                time_slot: '',
                truck_number: '',
                trailer_number: '',
                driver_name: '',
                driver_phone: '',
                notes: '',
                email_id: ''
            });
            props.handlePageAction('shipmentslist')
        }
    }, [props.error, props.globalMessage])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data) {
            setFormData({
                ...formData,
                po_number: props.data.po_number,
                supplier_name: props.data.supplier_name,
                supplier_location: props.data.supplier_location,
                delivery_location: props.data.delivery_location,
                transport_company: props.data.transport_company,
                shipment_type: props.data.shipment_type,
                shipment_date: props.data.shipment_date,
                time_slot: props.data.time_slot,
                truck_number: props.data.truck_number,
                trailer_number: props.data.trailer_number,
                driver_name: props.data.driver_name,
                driver_phone: props.data.driver_phone,
                notes: props.data.notes,
                email_id: props.data.email_id
            })
        }
    }, [props.data]);

    return (
        <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">Add Shipment Time Slot</h2>
            {props.error && props.error?.length > 0 && (
                <Banner2 type="error" open='true' className='no-close mb-4'>
                    {props.error}
                </Banner2>
            )}
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="shipment_id" value={shipmentId} />
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        PO Number <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="po_number"
                        name="po_number"
                        className="form-input w-full"
                        type="text"
                        value={formData.po_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Supplier Name <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="supplier_name"
                        name="supplier_name"
                        className="form-input w-full"
                        type="text"
                        value={formData.supplier_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Supplier Location <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="supplier_location"
                        name="supplier_location"
                        className="form-input w-full"
                        type="text"
                        value={formData.supplier_location}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Delivery Location <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="delivery_location"
                        name="delivery_location"
                        className="form-input w-full"
                        type="text"
                        value={formData.delivery_location}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Transport Company <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="transport_company"
                        name="transport_company"
                        className="form-input w-full"
                        type="text"
                        value={formData.transport_company}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Shipment Type <span className="text-rose-500">*</span>
                    </label>
                    <div className="flex flex-wrap items-center -m-3">
                        <div className="m-3">
                            {/* Start */}
                            <label className="flex items-center">
                                <input type="radio" name="shipment_type" className="form-radio" value="loading" checked={formData.shipment_type === "loading"} onChange={handleShipmentTypeChange} />
                                <span className="text-sm ml-2">Loading</span>
                            </label>
                            {/* End */}
                        </div>

                        <div className="m-3">
                            {/* Start */}
                            <label className="flex items-center">
                                <input type="radio" name="shipment_type" className="form-radio" value="unloading" checked={formData.shipment_type === "unloading"} onChange={handleShipmentTypeChange} />
                                <span className="text-sm ml-2">Unloading</span>
                            </label>
                            {/* End */}
                        </div>
                    </div>

                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Shipment Date <span className="text-rose-500">*</span>
                    </label>
                    <DatePicker
                        id="shipment_date"
                        name="shipment_date"
                        selected={selectedDate}
                        className="form-input w-full"
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Time Slots <span className="text-rose-500">*</span>
                    </label>
                    <select
                        id="time_slot"
                        name="time_slot"
                        className="form-select w-full"
                        value={selectedTimeSlot}
                        onChange={handleTimeSlotChange}
                        required
                    >
                        <option value="">Select a Time Slot</option>
                        {timeslots.map((slot, index) => (
                            <option key={index} value={`${slot.startTime}-${slot.endTime}`}>
                                {slot.startTime} - {slot.endTime}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Email Id <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="email_id"
                        name="email_id"
                        className="form-input w-full"
                        type="text"
                        value={formData.email_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Truck Number <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="truck_number"
                        name="truck_number"
                        className="form-input w-full"
                        type="text"
                        value={formData.truck_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Trailer Number <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="trailer_number"
                        name="trailer_number"
                        className="form-input w-full"
                        type="text"
                        value={formData.trailer_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Driver Name <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="driver_name"
                        name="driver_name"
                        className="form-input w-full"
                        type="text"
                        value={formData.driver_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Driver Pnone No. <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="driver_phone"
                        name="driver_phone"
                        className="form-input w-full"
                        type="text"
                        value={formData.driver_phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Notes
                    </label>
                    <input
                        id="notes"
                        name="notes"
                        className="form-input w-full"
                        type="text"
                        value={formData.notes}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex items-center justify-end">
                    {props.editMode && (
                        <button
                            type="button"
                            className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                            onClick={() => {
                                props.handlePageAction('list')
                            }}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-1.5"
                    >
                        {props.editMode ? 'Update' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = (state) => ({
    // Map your state to props
    loadingStatus: state.shipments.loading,
    timeSlots: state.shipments.timeSlots,
    shipmentDetails: state.shipments.shipmentDetails,
    error: state.error.message,
    globalMessage: state.globalMessage.message,
})

const mapDispatchToProps = (dispatch) => ({
    createShipmentTimeslotAction: (...args) => dispatch(createShipmentTimeslotAction(...args)),
    fetchTimeSlots: (...args) => dispatch(fetchTimeSlots(...args)),
    fetchShipmentDetails: (id) => dispatch(fetchShipmentDetails(id)),
})


// Connect your component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(ShipmentTimeSlotForm)
