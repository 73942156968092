import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { fetchShipmentTimeSlots } from './../../state/shipments/shipmentsActions';
import '../../css/fullcalendarcustomstyle.css'
import { eventColor, getRandomColor  } from '../../utils/UIUtils'

const ShipmentCalendar = ({
    fetchShipmentTimeSlots,
    shipmentTimeSlots,
    handlePageAction
}) => {
    const calendarRef = useRef(null);
    const [shipmentslots, setShipmentSlots] = useState([]);
    const [currentView, setCurrentView] = useState('dayGridMonth');
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const handlePreviousMonth = () => {
        if (month === 0) {
            setMonth(11);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
    };

    const handleNextMonth = () => {
        if (month === 11) {
            setMonth(0);
            setYear(year + 1);
        } else {
            setMonth(month + 1);
        }
    };  

    useEffect(() => {
        fetchShipmentTimeSlots();
    }, [fetchShipmentTimeSlots]);

    useEffect(() => {
        if (shipmentTimeSlots && shipmentTimeSlots.dataList) {
            const eventsData = shipmentTimeSlots.dataList.map(shipment => ({
                title: `${shipment.shipment_id} - ${shipment.po_number}`,
                start: new Date(shipment.shipment_date + ' ' + shipment.opening_time),
                end: new Date(shipment.shipment_date + ' ' + shipment.closing_time),
                drivername: `${shipment.driver_name}`,
                driverphone: `${shipment.driver_phone}`,
                classNames: eventColor(getRandomColor())
            }));
            setShipmentSlots(eventsData);
        }
    }, [shipmentTimeSlots]);

    useEffect(() => {
        console.log("Shipment Slots:", shipmentslots);
    }, [shipmentslots]);

    useEffect(() => {
        if (calendarRef.current) {
            calendarRef.current.getApi().changeView(currentView);
        }
    }, [currentView]);

    const renderEventContent = ({ event }) => {
        return (

            <div style={{ whiteSpace: 'nowrap' }}>
                <div class="text-xs font-semibold truncate">{event.title}</div>
                <div class="text-xs uppercase truncate hidden sm:block"><span>{event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span><span>- <span>{event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span></span></div>
            </div>

        );
    };

    return (
        <div>
            <div className="sm:flex sm:justify-between sm:items-center mb-4">

                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold"><span>{`${monthNames[month]} ${year}`}</span> ✨</h1>
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                    {/* Previous month button */}
                    <button
                        className="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
                        disabled={month === 0 ? true : false}
                        onClick={() => { handlePreviousMonth(); calendarRef.current.getApi().prev(); }}
                    >
                        <span className="sr-only">Previous month</span><wbr />
                        <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                            <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                        </svg>
                    </button>

                    {/* Next month button */}
                    <button
                        className="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
                        disabled={month === 11 ? true : false}
                        onClick={() => { handleNextMonth(); calendarRef.current.getApi().next(); }}
                    >
                        <span className="sr-only">Next month</span><wbr />
                        <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                        </svg>
                    </button>

                    <hr className="w-px h-full bg-slate-200 dark:bg-slate-700 border-none mx-1" />

                    {/* List Shipments */}
                    <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={() => handlePageAction("list")}>
                        <span className="hidden xs:block ml-2">List Shipments</span>
                    </button>

                </div>

            </div>
            <div className="fc-header-toolbar">
                <div className="flex flex-nowrap -space-x-px justify-end">
                    <button className="btn bg-slate-50 dark:bg-slate-900 border-slate-200 dark:border-slate-700 hover:bg-slate-50 text-indigo-500 rounded-none first:rounded-l last:rounded-r" onClick={() => setCurrentView('dayGridMonth')}>Month</button>
                    <button className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300 rounded-none first:rounded-l last:rounded-r" onClick={() => setCurrentView('timeGridWeek')}>Week</button>
                    <button className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300 rounded-none first:rounded-l last:rounded-r" onClick={() => setCurrentView('list')}>Day</button>
                </div>
            </div>
            <div className="bg-white dark:bg-slate-800 rounded-sm shadow overflow-hidden">
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                    initialView={currentView}
                    dayHeaderFormat={{ weekday: 'long' }}
                    events={shipmentslots}
                    eventContent={renderEventContent}
                    headerToolbar={{
                        start: '', // Hide default buttons from the start section
                        center: '', // Hide default buttons from the center section
                        end: '', // Hide default buttons from the end section
                    }}
                    today={false}
                />
            </div>
        </div>
    );
};


const mapStateToProps = (state) => ({
    shipmentTimeSlots: state.shipments.shipmentTimeSlots ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    fetchShipmentTimeSlots: (...args) => dispatch(fetchShipmentTimeSlots(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCalendar);
