import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Logo from "../components/Logo";
import OnboardingDecoration from "../images/auth-decoration.png";
import OnboardingImage from "../images/onboarding-image.jpg";
import Onboarding02 from "./Onboarding02";
import Onboarding03 from "./Onboarding03";
import Onboarding04 from "./Onboarding04";
import { selfRegisterUser } from "../state/auth/authActions";
import { selectErrorMessage } from "../state/errorSlice"
import Banner2 from './../components/Banner2'

function Onboarding() {
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(1);
	const globalerror  = useSelector(selectErrorMessage);
  const allSteps = [1, 2];
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm();

  const submitForm = (data) => {
    dispatch(selfRegisterUser(data));
  };

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  useEffect(() => {
    if (success && currentStep == 1) {
      setCurrentStep(2);
    }
  }, [success]);

  const watchedFields = watch();

  const areAllFieldsFilled = () => {
    switch (currentStep) {
      case 1:
        return (
					!!watchedFields.organization_no &&
          !!watchedFields.email_id &&
          !!watchedFields.company_name &&
          !!watchedFields.contact_person &&
          !!watchedFields.password &&
          !!watchedFields.confirm_password &&
          !!watchedFields.address
        );
      default:
        return false;
    }
  };

  const passwordsMatch = () => {
    const { password, confirm_password } = getValues();
    return password === confirm_password;
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                <Logo />
                <div className="text-sm">
                  Have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
              <div className="px-4 pt-12 pb-8">
                <div className="max-w-md mx-auto w-full">
                  <div className="relative">
                    <div
                      className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700"
                      aria-hidden="true"
                    ></div>
                    <ul className="relative flex justify-between w-full">
                      {allSteps.map((step) => (
                        <li key={step}>
                          <button
                            className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${
                              step <= currentStep
                                ? `bg-indigo-500 text-white`
                                : `bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400`
                            }`}
                            onClick={() => setCurrentStep(step)}
                            disabled={
                              currentStep === allSteps.length ||
                              step === allSteps.length
                            }
                          >
                            {step}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-8">
              <div className="max-w-md mx-auto">
								{globalerror && globalerror.length > 0 && (
									<Banner2 type="error" open="true" className="no-close">
										{globalerror}
									</Banner2>
								)}
                {currentStep === 1 && (
                  <Onboarding02
                    register={register}
                    watchedFields={watchedFields}
                    errors={errors}
                  />
                )}
                {currentStep === 2 && <Onboarding04 />}
                {currentStep < allSteps.length && (
                  <div className="flex items-center justify-between">
                    <button
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto"
                      onClick={() => {
                        if (currentStep === 1) {
                          handleSubmit(submitForm)();
                        } else {
                          setCurrentStep(currentStep + 1);
                        }
                      }}
                      disabled={!areAllFieldsFilled() || !passwordsMatch()}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={OnboardingDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default Onboarding;
