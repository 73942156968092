const isProd = false

export class CustomLogger {
    constructor() {
      this.logColor = {
        INFO: '#0040E4',
        ERROR: '#FF4747',
        DEFAULT: '#000',
      }
    }
    info(...args) {
      if (isProd) {
        console.info(...args)
      } else {
        const modifiedArgs = this.modifyLogUI(...args)
        console.info(...modifiedArgs)
      }
    }
  
    log(...args) {
      if (isProd) {
        console.log(...args)
      } else {
        const modifiedArgs = this.modifyLogUI(...args)
        console.log(...modifiedArgs)
      }
    }
  
    error(...args) {
      if (isProd) {
        console.error(...args)
      } else {
        const modifiedArgs = this.modifyLogUI(...args)
        console.error(...modifiedArgs)
      }
    }

    debug(...args) {
        if (isProd) {
            console.debug(...args)
        } else {
          const modifiedArgs = this.modifyLogUI(...args)
          console.debug(...modifiedArgs)
        }
      }
    
    modifyLogUI(...args) {
        const infoText = args?.[0]
        if (typeof infoText === 'string') {
          args?.shift()
          const color = infoText?.includes('ERROR')
            ? this.logColor.ERROR
            : infoText?.includes('INFO')
            ? this.logColor.INFO
            : this.logColor.DEFAULT
          let modifiedArgs = [`%c${infoText}`, `color: ${color}; font-size: 12px`, ...args]
          return modifiedArgs
        }
        return args
      }
    }
    
export const Logger = new CustomLogger()
