import { createSlice } from "@reduxjs/toolkit"
import { fetchStatus, createStatusAction } from "./statusActions"

const initialState = {
  statusList: [],
  loading: false,
}

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    statusList: (state, action) => {
      state.statusList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatus.fulfilled, (state, { payload }) => {
        console.log('fetchStatus REDUCER FULLFILLED.....', payload)
        state.statusList = payload
        state.loading = false
      })
      .addCase(createStatusAction.fulfilled, (state, { payload }) => {
        console.log('createStatusAction REDUCER FULLFILLED.....', payload)
        state.loading = false
      })
      .addCase(createStatusAction.rejected, (state, { payload }) => {
        state.loading = false
        console.log('inside createStatusAction.rejected:: ', state, payload)
      });
  },
})

export default statusSlice.reducer;
