import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import Banner2 from './../../../components/Banner2'
import WeekdayCheckboxes from "../../../pages/component/WeekdaysCheckboxes"


import { createTimeslotAction, updateTimeslotAction } from "./../../../state/timeslots/timeslotsActions"

function TimeslotsForm(props) {
    const defaultTime = '00:00';

    const [formData, setFormData] = useState({
        week_days: [], // Initialize as an array
        opening_time: defaultTime, // Initial opening time
        closing_time: defaultTime, // Initial closing time
        slot_duration: '',
        email_id: '',
    });

    const [errors, setErrors] = useState({});

    // Function to handle selected weekdays
    const handleSelectedWeekdays = (selectedWeekdays) => {
        setFormData({ ...formData, week_days: selectedWeekdays });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        // Check for checkbox validation
        if (formData.week_days.length === 0) {
            newErrors = { ...newErrors, checkbox: 'Please select at least one checkbox.' };
        } else {
            newErrors = { ...newErrors, checkbox: '' };
        }

        // Validate other fields
        if (formData.opening_time === defaultTime) {
            newErrors = { ...newErrors, opening_time: 'Opening time is required.' };
        } else {
            newErrors = { ...newErrors, opening_time: '' };
        }

        if (formData.closing_time === defaultTime) {
            newErrors = { ...newErrors, closing_time: 'Closing time is required.' };
        } else {
            newErrors = { ...newErrors, closing_time: '' };
        }

        if (formData.slot_duration === '') {
            newErrors = { ...newErrors, slot_duration: 'Slot duration is required.' };
        } else {
            newErrors = { ...newErrors, slot_duration: '' };
        }


        // Set errors state
        setErrors(newErrors);

        // If no errors, submit the form
        if (Object.values(newErrors).every(error => !error)) {
            if (props.editMode) {
                props.updateTimeslotAction({ id: props.data.id, ...formData });
            } else {
                props.createTimeslotAction(formData);
            }
        }
    };

    useEffect(() => {
        if (props.globalMessage && props.globalMessage.length > 0) {
            setFormData({ week_days: [], opening_time: '', closing_time: '', slot_duration: '', email_id: '' });
            props.handlePageAction('list')
        }
    }, [props.error, props.globalMessage])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data) {
            setFormData({
                ...formData,
                week_days: props.data.week_days,
                opening_time: props.data.opening_time,
                closing_time: props.data.closing_time,
                slot_duration: props.data.slot_duration,
            })
        }
    }, [props.data]);

    return (
        <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">{props.editMode ? 'Update' : 'Add'} Time Slot</h2>
            {props.error && props.error?.length > 0 && (
                <Banner2 type="error" open='true' className='no-close mb-4'>
                    {props.error}
                </Banner2>
            )}
            <form onSubmit={handleSubmit}>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                        Opening Days <span className="text-rose-500">*</span>
                    </label>
                    <WeekdayCheckboxes selectedWeekdays={formData.week_days} setSelectedWeekdays={handleSelectedWeekdays} setError={error => setErrors({ ...errors, checkbox: error })} />
                    {errors.checkbox && <div className="text-red-500">{errors.checkbox}</div>}
                </div>
                <div className="flex space-x-4 mb-8"> {/* Added flex container */}
                    <div className="space-y-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                            Opening Time <span className="text-rose-500">*</span>
                        </label>
                        <input
                            type="time"
                            id="opening_time"
                            name="opening_time"
                            className="form-input w-full px-2 py-1"
                            value={formData.opening_time}
                            onChange={handleChange}
                        />
                        {errors.opening_time && formData.opening_time === defaultTime && (
                            <span className="text-red-500">{errors.opening_time}</span>
                        )}
                    </div>
                    <div className="space-y-4">
                        <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                            Closing Time <span className="text-rose-500">*</span>
                        </label>
                        <input
                            type="time"
                            id="closing_time"
                            name="closing_time"
                            className="form-input w-full px-2 py-1"
                            value={formData.closing_time}
                            onChange={handleChange}
                        />
                        {errors.closing_time && formData.closing_time === defaultTime && (
                            <span className="text-red-500">{errors.closing_time}</span>
                        )}
                    </div>
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                        Slot Duration <span className="text-rose-500">*</span>
                    </label>
                    <select
                        id="slot_duration"
                        name="slot_duration"
                        className="form-select"
                        value={formData.slot_duration}
                        onChange={handleChange}
                    >
                        <option value="">Select Duration</option>
                        <option value="30">30 Mins</option>
                        <option value="60">60 Mins</option>
                        <option value="90">90 Mins</option>
                        <option value="120">120 Mins</option>
                    </select>
                    {errors.slot_duration && <div className="text-red-500">{errors.slot_duration}</div>}
                </div>
                <div className="flex items-center justify-end">
                    {props.editMode && (
                        <button
                            type="button"
                            className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                            onClick={() => {
                                props.handlePageAction('list')
                            }}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-1.5"
                    >
                        {props.editMode ? 'Update' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = (state) => ({
    // Map your state to props
    loadingStatus: state.timeslots.loading,
    error: state.error.message,
    globalMessage: state.globalMessage.message
})

const mapDispatchToProps = (dispatch) => ({
    createTimeslotAction: (...args) => dispatch(createTimeslotAction(...args)),
    updateTimeslotAction: (...args) => dispatch(updateTimeslotAction(...args)),
})


// Connect your component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(TimeslotsForm)
