import React, { useState, useRef } from "react";

const Dropzone = ({ onUpload }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef();

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    onUpload(files);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files);
    onUpload(files);
  };

  return (
    <div
      className={`flex justify-center items-center w-full h-48 border-2 border-dashed rounded-lg p-5
      hover:bg-sky-50 hover:border-sky-400
        ${isDragActive ? "bg-sky-50 border-sky-400" : "border-gray-300"}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        onChange={handleFileInput}
      />
      <p
        className={`text-sm ${
          isDragActive ? "text-sky-800" : "text-gray-400"
        }  `}
      >
        {isDragActive
          ? "Leave Your File Here"
          : "Drag and drop your files here or click to browse files"}
      </p>
    </div>
  );
};

export default Dropzone;