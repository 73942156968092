import React, { useState } from "react";

import CitiesForm from "../../partials/settings/cities/Add";
import CitiesTable from "../../partials/settings/cities/CitiesTable";
import DeleteButton from "../../partials/actions/DeleteButton";
import SettingsLayout from "./SettingsLayout";
import { PageContainer, PageActions } from "../component/PageContainer";

function CitiesList() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [pageType, setPageType] = useState("list");

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handlePageAction = (type, selectedItem) => {
    setPageType(type);
		if(selectedItem) {
			const selItem = {city: selectedItem.name, id: selectedItem.id, status: selectedItem.status}
			setSelectedItem(selItem);
		}
  };

  const actions = (
    <PageActions
      pageType={pageType}
      handlePageAction={handlePageAction}
      deleteButton={<DeleteButton selectedItems={selectedItems} />}
      addButtonLabel="Add New City"
      listButtonLabel="List Cities"
    />
  );

  return (
    <SettingsLayout>
      <PageContainer title="Cities" actions={actions}>
        {/* Table or form based on pageType */}
        {pageType === "add" || pageType === "edit" ? (
          <CitiesForm 
						selectedItems={handleSelectedItems} 
						data={selectedItem} 
						editMode={pageType === "edit"}
						handlePageAction={handlePageAction}
					/>
        ) : (
          <CitiesTable
            selectedItems={handleSelectedItems}
            handlePageAction={handlePageAction}
          />
        )}
      </PageContainer>      
    </SettingsLayout>
  );
}

export default CitiesList;
