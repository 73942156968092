import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ShipmentsTableItem from './ShipmentsTableItem';
import { fetchShipments } from '../../state/shipments/shipmentsActions';
import PaginationClassic from '../../components/PaginationClassic';
import { Logger } from '../../utils/Logger';
import TableHeader from '../../pages/component/TableHeader';
import { formatDate } from '../../utils/Utils'

const ShipmentsTable = ({
    selectedItems,
    handlePageAction,
    fetchShipments,
    shipmentsList,
}) => {
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        Logger.log('ShipmentsTable - fetching page - ....', currentPage);
        fetchShipments(currentPage);
    }, [currentPage, fetchShipments]);

    useEffect(() => {
        if (shipmentsList && shipmentsList.dataList) {
            setList(shipmentsList.dataList);
        }
    }, [shipmentsList]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setIsCheck(list.map((li) => li.id));
        if (selectAll) {
            setIsCheck([]);
        }
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        setSelectAll(false);
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        selectedItems(isCheck);
    }, [isCheck, selectedItems]);

    return (
        <>
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
                <header className="px-5 py-4">
                    <h2 className="font-semibold text-slate-800 dark:text-slate-100">
                        All Shipments{' '}
                        <span className="text-slate-400 dark:text-slate-500 font-medium">
                            {shipmentsList ? shipmentsList.totalCount : 0}
                        </span>
                    </h2>
                </header>
                <div>
                    {/* Table */}
                    <div className="overflow-x-auto">
                        {list.length > 0 ? (
                            <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                                {/* Table header */}
                                <TableHeader
                                    columns={[
                                        'Shipment Id',
                                        'Purchase Order',
                                        'Supplier Name',
                                        'Shipment Date',
                                        'Expected Delivery Date',
                                        'Shipping Destination',
                                        'Status',
                                        'Actions'
                                    ]}
                                    selectAll={selectAll}
                                    handleSelectAll={handleSelectAll}
                                />
                                {/* Table body */}
                                {list.map((listitem) => {
                                    return (
                                        <ShipmentsTableItem
                                            key={listitem.id}
                                            id={listitem.id}
                                            po_number={listitem.po_number}
                                            supplier_name={listitem.supplier_name}
                                            created_on={formatDate(listitem.created_on)}
                                            expected_delivery_date={formatDate(listitem.expected_delivery_date)}
                                            shipping_destination={listitem.shipping_destination}
                                            status={listitem.status}
                                            handleClick={handleClick}
                                            handlePageAction={handlePageAction}
                                            isChecked={isCheck.includes(listitem.id)}
                                        />
                                    );
                                })}
                            </table>
                        ) : (
                            <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                                No Shipments found
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <PaginationClassic
                    currentPage={currentPage}
                    total={shipmentsList ? shipmentsList.totalCount : 0}
                    handlePageChange={handlePageChange}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    shipmentsList: state.shipments.shipmentsList ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    fetchShipments: (...args) => dispatch(fetchShipments(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsTable);
