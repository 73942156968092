import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import Banner2 from './../../../components/Banner2'

import { createBookingtagAction, updateBookingtagAction } from "./../../../state/bookingtags/bookingtagsActions"

function BookingtagsForm(props) {
    const [formData, setFormData] = useState({
        tag_name: '',
        email_id: '',
        littera_no: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.editMode) {
            // If in edit mode, dispatch updateBookingtagAction with individual parameters
            props.updateBookingtagAction({ id: props.data.id, ...formData });
        } else {
            // If not in edit mode, dispatch createBookingtagAction with formData
            props.createBookingtagAction(formData);
        }
    }

    useEffect(() => {
        if (props.globalMessage && props.globalMessage.length > 0) {
            setFormData({ tag_name: '', email_id: '', littera_no: '' });
            props.handlePageAction('list')
        }
    }, [props.error, props.globalMessage])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data) {
            setFormData({
                ...formData,
                tag_name: props.data.tag_name,
                email_id: props.data.email_id,
                littera_no: props.data.littera_no
            })
        }
    }, [props.data]);

    return (
        <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">{props.editMode ? 'Update' : 'Add'} booking tag</h2>
            {props.error && props.error?.length > 0 && (
                <Banner2 type="error" open='true' className='no-close mb-4'>
                    {props.error}
                </Banner2>
            )}
            <form onSubmit={handleSubmit}>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                        Tag Name <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="tag_name"
                        name="tag_name"
                        className="form-input w-full"
                        type="text"
                        value={formData.tag_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Email
                    </label>
                    <input
                        id="email_id"
                        name="email_id"
                        className="form-input w-full"
                        type="text"
                        value={formData.email_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Littera No
                    </label>
                    <input
                        id="littera_no"
                        name="littera_no"
                        className="form-input w-full"
                        type="text"
                        value={formData.littera_no}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex items-center justify-end">
                    {props.editMode && (
                        <button
                            type="button"
                            className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                            onClick={() => {
                                props.handlePageAction('list')
                            }}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-1.5"
                    >
                        {props.editMode ? 'Update' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = (state) => ({
    // Map your state to props
    loadingStatus: state.countries.loading,
    error: state.error.message,
    globalMessage: state.globalMessage.message
})

const mapDispatchToProps = (dispatch) => ({
    createBookingtagAction: (...args) => dispatch(createBookingtagAction(...args)),
    updateBookingtagAction: (...args) => dispatch(updateBookingtagAction(...args)),
})


// Connect your component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(BookingtagsForm)
