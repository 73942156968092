// SettingsLayout.js
import React from "react";
import CommonPageLayout from "../component/CommonPageLayout";

const SettingsLayout = ({ children }) => {
  return (
    <CommonPageLayout pageTitle="Account Settings">
      <div className="flex flex-col md:flex-row md:-mr-px">
        {children}
      </div>
    </CommonPageLayout>
  );
};

export default SettingsLayout;
