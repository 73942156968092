import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";

import { API_DOMAIN } from "../../config";
import { Logger } from "../../utils/Logger";
import { setGlobalMsg } from './../globalMessageSlice'
import { setErrorMsg } from "./../errorSlice";

const backendURL = API_DOMAIN;
const cookies = new Cookies();
const token = cookies.get("userToken") || null;

export const fetchShipments = createAsyncThunk(
    "shipments/shipmentlist",
    async (page, { rejectWithValue }) => {
        try {
            Logger.log(
                "state/action - fetchShipments - page:" + page + ", token:",
                token
            );
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            var url = backendURL + "/shipment";
            if (page) {
                url += "?page=" + page;
            }
            Logger.log("state/action - fetchShipments - url:", url);

            const { data } = await axios.get(url, config);
            Logger.log("state/action - fetchShipments - Zones DATA:", data);
            return data.data;
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const fetchTimeSlots = createAsyncThunk(
    "shipments/fetchtimeslots",
    async ({ shipment_date }, { rejectWithValue }) => {
        try {
            Logger.log("state/action - fetchTimeSlots ----", token, shipment_date);
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            var url = `${backendURL}/timeslot/slots/${shipment_date}`;

            Logger.log("state/action - fetchTimeSlots - url:", url);

            const { data } = await axios.get(url, config);

            Logger.log("state/action - fetchTimeSlots - Zones DATA:", data);
            return data;
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const fetchShipmentTimeSlots = createAsyncThunk(
    "shipments/shipmenttimeslots",
    async (page, { rejectWithValue }) => {
        try {
            Logger.log(
                "state/action - fetchShipmentTimeSlots - page:" + page + ", token:",
                token
            );
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            var url = backendURL + "/shipmenttimeslot";
            if (page) {
                url += "?page=" + page;
            }
            Logger.log("state/action - fetchShipmentTimeSlots - url:", url);

            const { data } = await axios.get(url, config);
            Logger.log("state/action - fetchShipmentTimeSlots - Zones DATA:", data);
            return data.data;
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const fetchShipmentDetails = createAsyncThunk(
    "shipments/shipmentdetails",
    async ({ id }, { rejectWithValue }) => {
        try {
            Logger.log("state/action - fetchShipmentDetails ----", token, id);
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            var url = `${backendURL}/shipment/id/${id}`;

            Logger.log("state/action - fetchShipmentDetails - url:", url);

            const { data } = await axios.get(url, config);
            Logger.log("state/action - fetchShipmentDetails - Zones DATA:", data);
            return data;
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createShipmentTimeslotAction = createAsyncThunk(
    "shipments/createtimeslot",
    async ({ shipment_id, po_number, supplier_name, supplier_location, delivery_location, transport_company, shipment_type, shipment_date, time_slot, truck_number, trailer_number, driver_name, driver_phone, email_id, notes }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setErrorMsg(""));
            // configure header's Content-Type as JSON

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            Logger.log("state/action - createShipmentTimeslotAction ----", token);
            const response = await axios.post(
                `${backendURL}/shipmenttimeslot/create`,
                {
                    shipment_id: shipment_id,
                    po_number: po_number,
                    supplier_name: supplier_name,
                    supplier_location: supplier_location,
                    delivery_location: delivery_location,
                    transport_company: transport_company,
                    shipment_type: shipment_type,
                    shipment_date: shipment_date,
                    time_slot: time_slot,
                    truck_number: truck_number,
                    trailer_number: trailer_number,
                    driver_name: driver_name,
                    driver_phone: driver_phone,
                    email_id: email_id,
                    notes: notes
                },
                config
            );
            const data = response.data
            Logger.log("state/action - shipment timeslot insert DATA.....:", data);
            if (response.status === 201) {
                dispatch(setGlobalMsg(data.message))
            }
            return data.data;
        } catch (error) {
            Logger.log(
                "state/action - bookings timeslots - Error:",
                error,
                error.response && error.response.data.message
            );
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                dispatch(setErrorMsg(error.response.data.message));
                //setErrorMsg(error.response.data.message)
                return rejectWithValue(error.response.data.message);
            } else {
                dispatch(setErrorMsg(error.message));
                return rejectWithValue(error.message);
            }
        }
    }
);


