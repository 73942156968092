import React from 'react'
import { statusColor, getStatusDisplay } from '../../utils/UIUtils'

function ShipmentsTableItem(props) {
    return (
        <tbody className="text-sm">
            {/* Row */}
            <tr>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="flex items-center">
                        <label className="inline-flex">
                            <span className="sr-only">Select</span>
                            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
                        </label>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.id}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.po_number}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.supplier_name}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.created_on}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.expected_delivery_date}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.shipping_destination}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{getStatusDisplay(props.status)}</div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="space-x-1">
                        <button
                            className="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full"
                            onClick={() => {
                                props.handlePageAction('add', props)
                            }}
                        >
                            <span className="sr-only">Add Time Slot</span>                            
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default ShipmentsTableItem
