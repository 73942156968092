import { createSlice } from "@reduxjs/toolkit"
import { fetchCities, createCityAction } from "./citiesActions"

const initialState = {
  citiesList: [],
  loading: false,
}

const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    citiesList: (state, action) => {
      state.citiesList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.fulfilled, (state, { payload }) => {
        console.log('fetchCities REDUCER FULLFILLED.....', payload)
        state.citiesList = payload
        state.loading = false
      })
      .addCase(createCityAction.fulfilled, (state, { payload }) => {
        console.log('createCityAction REDUCER FULLFILLED.....', payload)
        state.loading = false
      })
      .addCase(createCityAction.rejected, (state, { payload }) => {
        state.loading = false
        console.log('inside createCityAction.rejected:: ', state, payload)
      });
  },
})

export default citiesSlice.reducer;
