import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import CountriesTableItem from './CountriesTableItem'
import { fetchCountries } from "../../../state/countries/countriesActions"
import PaginationClassic from "../../../components/PaginationClassic"
import { Logger } from '../../../utils/Logger'
import TableHeader from "../../../pages/component/TableHeader"

const CountriesTable = ({
  selectedItems,
  handlePageAction,
  fetchCountries,
  countriesList }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    Logger.log('CountriesTable - fetching page - ....', currentPage);
    fetchCountries(currentPage);
  }, [currentPage, fetchCountries]);

  useEffect(() => {
    if (countriesList && countriesList.dataList) {
      setList(countriesList.dataList);
    }
  }, [countriesList]);  

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  }

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <>
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
        <header className="px-5 py-4">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">All Countries <span className="text-slate-400 dark:text-slate-500 font-medium">{countriesList.totalCount}</span></h2>
        </header>
        <div>

          {/* Table */}
          <div className="overflow-x-auto">
            {list.length > 0 ? (
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {/* Table header */}
                <TableHeader columns={['Id', 'Name', 'Iso Code', 'Currency', 'Status', 'Actions']} selectAll={selectAll} handleSelectAll={handleSelectAll} />
                {/* Table body */}
                {
                  list.map(listitem => {
                    return (
                      <CountriesTableItem
                        key={listitem.id}
                        id={listitem.id}
                        name={listitem.name}
                        iso_code={listitem.iso_code}
                        currency={listitem.currency}
                        status={listitem.status}
                        handleClick={handleClick}
                        handlePageAction={handlePageAction}
                        isChecked={isCheck.includes(listitem.id)}
                      />
                    )
                  })
                }
              </table>
            ) : (
              <p className="text-center text-gray-600 dark:text-gray-400 py-4">
                No Countries found
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <PaginationClassic
          currentPage={currentPage}
          total={countriesList.totalCount}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  countriesList: state.countries.countriesList ?? [],
})

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: (...args) => dispatch(fetchCountries(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CountriesTable)
