import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./../state/rootReduces";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth/error", "error"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState) => {
  return configureStore({
    devTools: process.env.NODE_ENV !== "production",
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ["error"],
        },
      });
    },
  });
};

export const store = setupStore();

setupListeners(store.dispatch);
const persistor = persistStore(store);
export default persistor;