import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import Banner2 from './../../../components/Banner2'

import { createCountryAction, updateCountryAction } from "./../../../state/countries/countriesActions"

function CountriesForm(props) {
    const [formData, setFormData] = useState({
        country: '',
        iso_code: '',
        currency: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.editMode) {
            // If in edit mode, dispatch updateCountryAction with individual parameters
            props.updateCountryAction({ id: props.data.id, ...formData });
        } else {
            // If not in edit mode, dispatch createCountryAction with formData
            props.createCountryAction(formData);
        }    
    }

    useEffect(() => {
        if (props.globalMessage && props.globalMessage.length > 0) {
            setFormData({ country: '', iso_code: '', currency: '' });
            props.handlePageAction('list')
        }
    }, [props.error, props.globalMessage])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data) {
            setFormData({ ...formData, 
                country: props.data.country,
                iso_code: props.data.iso_code,
                currency: props.data.currency 
            })
        }
    }, [props.data]);    

    return (
        <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">{props.editMode ? 'Update' : 'Add'} country</h2>
            {props.error && props.error?.length > 0 && (
                <Banner2 type="error" open='true' className='no-close mb-4'>
                    {props.error}
                </Banner2>
            )}
            <form onSubmit={handleSubmit}>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="mandatory">
                        Country Name <span className="text-rose-500">*</span>
                    </label>
                    <input
                        id="country"
                        name="country"
                        className="form-input w-full"
                        type="text"
                        value={formData.country}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Iso Code
                    </label>
                    <input
                        id="iso_code"
                        name="iso_code"
                        className="form-input w-full"
                        type="text"
                        value={formData.iso_code}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="space-y-4 mb-8">
                    <label className="block text-sm font-medium mb-1" htmlFor="default">
                        Currency
                    </label>
                    <input
                        id="currency"
                        name="currency"
                        className="form-input w-full"
                        type="text"
                        value={formData.currency}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex items-center justify-end">
                    {props.editMode && (
                        <button
                            type="button"
                            className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                            onClick={() => {
                                props.handlePageAction('list')
                            }}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-1.5"
                    >
                        {props.editMode ? 'Update' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = (state) => ({
    // Map your state to props
    loadingStatus: state.countries.loading,
    error: state.error.message,
    globalMessage: state.globalMessage.message
})

const mapDispatchToProps = (dispatch) => ({
    createCountryAction: (...args) => dispatch(createCountryAction(...args)),
    updateCountryAction: (...args) => dispatch(updateCountryAction(...args)),
})


// Connect your component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(CountriesForm)
