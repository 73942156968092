import React from 'react'
import { ITMES_PER_PAGE } from '../config'

function PaginationClassic({currentPage, total, handlePageChange}) {
  const itemsPerPage = ITMES_PER_PAGE
  const start = ((currentPage - 1) * itemsPerPage) + 1
  const end = (currentPage * itemsPerPage) < total ? (currentPage * itemsPerPage) : total

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav className="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0">
            {start > 1 && (
              <button className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500" onClick={() => handlePageChange(currentPage-1)}>&lt;- Previous</button>
            )}
            {start === 1 && (
              <span className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600">&lt;- Previous</span>  
            )}
          </li>
          <li className="ml-3 first:ml-0">
            {end < total && (
              <button className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500" onClick={() => handlePageChange(currentPage+1)}>Next -&gt;</button>
            )}
            {end === total && (
              <span className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600">Next -&gt;</span>
            )}
          </li>
        </ul>
      </nav>
      <div className="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left">
        Showing <span className="font-medium text-slate-600 dark:text-slate-300">{ start }</span> to <span className="font-medium text-slate-600 dark:text-slate-300">{ end }</span> of <span className="font-medium text-slate-600 dark:text-slate-300">{ total }</span> results
      </div>
    </div>
  );
}

export default PaginationClassic;
