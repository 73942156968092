// errorSlice.js
import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    message: null,
  },
  reducers: {
    setErrorMsg: (state, action) => {
      console.log('inside seterror method: state:', state, action)
      state.message = action.payload;
    },
    clearErrorMsg: (state) => {
      state.message = null;
    },
  },
});

export const { setErrorMsg, clearErrorMsg } = errorSlice.actions;
export const selectErrorMessage = (state) => state.error.message;

export default errorSlice.reducer;
