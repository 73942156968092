import React from 'react'
import { statusColor } from './../../../utils/UIUtils'
import moment from 'moment';

function TimeslotsTableItem(props) {
    const daysArray = props.week_days.split(',');
    return (
        <tbody className="text-sm">
            {/* Row */}
            <tr>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="flex items-center">
                        <label className="inline-flex">
                            <span className="sr-only">Select</span>
                            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
                        </label>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-medium text-sky-500">{props.id}</div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">
                            {daysArray.map((day, index) => (
                                <div key={index} className="font-medium text-slate-800 dark:text-slate-100">
                                    {day.trim()}
                                </div>
                            ))}
                        </div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{moment(props.opening_time, "HH:mm:ss").format("HH:mm")}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{moment(props.closing_time, "HH:mm:ss").format("HH:mm")}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.slot_duration}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center text-slate-800">
                        <div className="font-medium text-slate-800 dark:text-slate-100">{props.email_id}</div>
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>{props.status}</div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="space-x-1">
                        <button
                            className="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full"
                            onClick={() => {
                                props.handlePageAction('edit', props)
                            }}
                        >
                            <span className="sr-only">Edit</span>
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
                            </svg>
                        </button>
                        <button
                            className="text-rose-500 hover:text-rose-600 rounded-full"
                            onClick={() => {
                                props.handlePageAction('delete', props)
                            }}
                        >
                            <span className="sr-only">Delete</span>
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                                <path d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default TimeslotsTableItem
