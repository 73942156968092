import { createSlice } from "@reduxjs/toolkit"
import { fetchSuppliers, fetchSupplierDetails, fetchSupplierAddresses, fetchSupplierPOList } from "./suppliersActions"

const initialState = {
  suppliersList: [],
  supplierDetails: {},
  supplierAddresses: {},
  supplierPOList: {},
  loading: false,
}

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    suppliersList: (state, action) => {
      state.suppliersList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuppliers.fulfilled, (state, { payload }) => {
        console.log('fetchSuppliers REDUCER FULLFILLED.....', payload)
        state.suppliersList = payload
        state.loading = false
      })
      .addCase(fetchSupplierDetails.fulfilled, (state, { payload }) => {
        console.log('fetchSupplierDetails REDUCER FULLFILLED.....', payload)
        state.supplierDetails = payload
        state.loading = false
      })
      .addCase(fetchSupplierAddresses.fulfilled, (state, { payload }) => {
        console.log('fetchSupplierAddresses REDUCER FULLFILLED.....', payload)
        state.supplierAddresses = payload
        state.loading = false
      })
      .addCase(fetchSupplierPOList.fulfilled, (state, { payload }) => {
        console.log('fetchSupplierPOList REDUCER FULLFILLED.....', payload)
        state.supplierPOList = payload
        state.loading = false
      });
  },
})

export default suppliersSlice.reducer;
