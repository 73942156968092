// PageContainer.js
import React from "react";

export function PageContainer({ title, actions, children }) {
  return (
    <main className="grow">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        {(title || actions) && (
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            {/* Left: Title */}
            <div className="mb-4 sm:mb-0">
              {title && title.length > 0 && (
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {title} ✨
                </h1>
              )}
            </div>
            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              {actions}
            </div>
          </div>
        )}
        {/* Main content */}
        {children}
      </div>
    </main>
  );
}

export function PageActions({ pageType, handlePageAction, deleteButton, addButtonLabel, listButtonLabel, approveButton }) {
  return (
    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
      {/* Delete button */}
      {deleteButton}
      {/* Approve button */}
      {approveButton}
      {/* Add or list button */}
      {pageType === "list" && addButtonLabel && (
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={() => handlePageAction("add")}
        >
          <svg
            className="w-4 h-4 fill-current opacity-50 shrink-0"
            viewBox="0 0 16 16"
          >
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
          <span className="hidden xs:block ml-2">{addButtonLabel}</span>
        </button>
      )}
      {/* List button */}
      {pageType !== "list" && (
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={() => handlePageAction("list")}
        >
          <span className="hidden xs:block ml-2">{listButtonLabel}</span>
        </button>
      )}
    </div>
  );
}

export function ShipmentPageActions({ pageType, handlePageAction, deleteButton, addButtonLabel, listButtonLabel }) {
  return (
    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
      {/* Delete button */}
      {deleteButton}
      {/* Add or list button */}
      {pageType === "list" ? (
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={() => handlePageAction("calendar")}
        >
          <span className="hidden xs:block ml-2">{addButtonLabel}</span>
        </button>
      ) : pageType === "calendar" ? (
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={() => handlePageAction("list")}
        >
          <span className="hidden xs:block ml-2">{listButtonLabel}</span>
        </button>
      ) : (
        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={() => handlePageAction("list")}
        >
          <span className="hidden xs:block ml-2">{listButtonLabel}</span>
        </button>
      )}
    </div>
  );
}
