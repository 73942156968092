import { createSlice } from "@reduxjs/toolkit";
//Added for testing
const initialState = {
  value: 0,
};

export const dashboardSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

export const { increment, decrement, incrementByAmount } = dashboardSlice.actions;

export default dashboardSlice.reducer;
//Added for testing
